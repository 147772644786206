import { isNative } from "./env";
const setRemUnit = () => {
  const rem = isNative() ? 20: 100; // 设置基准字体大小（默认为16px）
  const screenWidth = Math.max((document.querySelector('body') as Element).getBoundingClientRect().width, 300);
  const designWidth = isNative() ? 414: 1920; // 设置设计稿的宽度，假设为PC端设计稿的宽度
  // 计算当前屏幕相对于设计稿的缩放比例
  const scale = screenWidth / designWidth;
  // 计算并设置根元素的字体大小（rem单位）
  document.documentElement.style.fontSize = rem * Math.min(scale, 2) + 'px';
}

const setWebTitle = (name?: string) => document.title = name ? `${name} - So.social` : 'So.social'

export {
  setRemUnit,
  setWebTitle
}
