import React from "react";
import cls from "classnames";
import s from './index.module.less';

interface ICta {
  keyword: string;
  className?: string;
  speed?: number;
  url?: string;
}

const Cta = ({keyword, className, speed = 45, url}: ICta) => {
  const keys = keyword.split('');

  return <div className={cls(s.cta, className)} onClick={() => url && window.open(url)}>
    <span className={s.parent}>
      <span className={s.current}>
        {keys.map((item, index) => <span
          className={s.letter}
          key={item + index}
          style={{transitionDelay: `${speed * index + 49}ms`}}
        >
          {item.charCodeAt(0) === 32 ? '' : item}
        </span>)}
      </span>
      <span className={s.buildin}>
        {keys.map((item, index) => <span
          className={s.letter}
          key={item + index}
          style={{transitionDelay: `${speed * index + 49}ms`}}
        >
          {item.charCodeAt(0) === 32 ? '' : item}
        </span>)}
      </span>
    </span>
  </div>
}

export default Cta;
