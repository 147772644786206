import { AxiosResponse } from "axios";
import request from "../request";

export function verifyCaptcha(data: string, sc: string): Promise<AxiosResponse<{success: boolean}>> {
  return request.post(`/api/f/v1/risk/verify-captcha`, {
      'captchaValue': data,
      "scenario": sc,
  })
}

export async function verifyLink(link: string, parentId: string, parentType: string) {
  return request.get(`/api/f/v1/risk/verify-link`, {params: {
      'link':link,
      'parentId':parentId,
      'parentType':parentType,
  }})
}