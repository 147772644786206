import React, { useEffect, useRef, useState } from "react";
import cls from "classnames";
import Fade from "../animation/components/fade";
import Title from "../title";
import Empty from "../empty";
import previewList from "./preview";
import s from './index.module.less';


const Preview = () => {
  const ref = useRef(null);
  const [queue, setQueue] = useState<number[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const loadingEntry = entries[0];
        if (loadingEntry.isIntersecting) {
          setQueue([1]);
          setTimeout(() => {
            setQueue([1, 2]);
            setTimeout(() => {
              setQueue([1, 2, 3]);
              setTimeout(() => {
                setQueue([1, 2, 3, 4]);
              }, 280);
            }, 220);
          }, 200);
          observer.disconnect();
        }
      },
      {
        threshold: 0.2, // 提前加载高度
      },
    )
    observer.observe(ref.current as unknown as Element);
  }, []);

  return <div className={s.preview}>
    <div className='radius'>
      <Empty height='0.6rem' />
      <Title text='App Preview' />
      <Empty height='0.6rem' />
      <div ref={ref}>
        {
          previewList.map((item, index) => {
            return <div
              key={item.title}
              className={cls(s.wrapper, {
                [s.left]: !item.left,
                [s.animation]: index < queue.length
              })}
            >
              {
                item.left
                  ? <>
                    <div className={s.img}>
                      <img src={item.img} alt="app preview" />
                    </div>
                    <div className={cls(s.title, s.left)}>
                      <Fade delay time={index < queue.length ? 1000: 0} keyword={item.title} />
                    </div>
                  </>
                  : <>
                    <div className={s.title}>
                      <Fade delay time={index < queue.length ? 1000: 0} keyword={item.title} />
                    </div>
                    <div className={s.img}>
                      <img src={item.img} alt="app preview" />
                    </div>
                  </>
              }
            </div>
          })
        }
      </div>
      <Empty height='0.6rem' />
    </div>
  </div>
}

export default Preview;
