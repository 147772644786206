import React from "react";
import stylePc from './index.module.less';
import styleMobile from './mobile.module.less';
import { isNative } from "@/utils/env";

const s = isNative() ? styleMobile : stylePc;

const Copyright = () => {
  const name = window.location.host.includes('.tech') ? '上海塔跃网络科技有限公司' : 'SoBit PTE. LTD.'
  return <div className={s.copyright}>
    ©2024 {name} • All Rights Reserved
    { isNative() ? <br /> : ' • '}
    Contact Us: &nbsp;
    <a href='mailto:contact@so.social' target='_blank' rel="noopener noreferrer">
      contact@so.social
    </a>
  </div>
}

export default Copyright;
