import FirstView from 'src/components/firstView/mobile';
import RealSocial from '../realSocial/mobile';
import HighLights from '../highlights/mobile';
import Preview from '../preview/mobile';
import News from '../news';
import Copyright from '../copyright';
import './index.less';

const MobileApp = () => <div className="AppMobile">
  <FirstView />
  <RealSocial />
  <HighLights />
  <Preview />
  <News />
  <Copyright />
</div>

export default MobileApp;
