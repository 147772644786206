import newParadigmAnimation from "./images/new-paradigm-animation.webp";
import realSocialAnimation from "./images/real-social-animation.webp";
import webSocialAnimation from "./images/web-social-animation.webp";
import versatileAnimation from "./images/versatile-animation.webp";
import assetAnimation from "./images/asset-animation.webp";
import standerAnimation from "./images/stander-animation.webp";

const highLightsList = [
  {
    img: newParadigmAnimation,
    title: 'Social Infrastructure Layer',
    text: 'Decentralize monolithic social platforms through the introduction of a new, modular social infrastructure layer that transforms the traditional social platforms into social scenario providers.',
  },
  {
    img: webSocialAnimation ,
    title: 'Sovereign DAO',
    text: 'A Sovereign DAO represents an evolution of the traditional DAO, emphasizing a higher degree of self-governance, independence, and control over its operations and interactions with the external environment. ',
  },
  {
    img: versatileAnimation,
    title: 'Social Consensus Mechanisms',
    text: "Groups, communities, and societies have different ways of making collective decisions. New social consensus mechanisms are introduced, i.e., Proof-of-Contribution and Proof-of-Reputation, to govern revenue streams, profit distribution, and content policies.",
  },
  {
    img: assetAnimation,
    title: 'Weaving Web3 Assets into Social Interactions',
    text: 'Unlike existing social protocols, So.Social establishes a tangible connection between digital assets and social interactions, without the need to tokenize every element. Let this connection solve social needs, like validating charity donations or recognizing creative content.',
  },
  {
    img: standerAnimation,
    title: 'Universal Standards for Social Assets',
    text: "So.Social's protocols allow for global connectivity of social assets and social interactions, which ensures a consistent display and utility across all social scenarios adopting the protocols. This will enhance user experience and increase the value of assets.",
  },
  {
    img: realSocialAnimation,
    title: 'Building Community Trust',
    text: 'A transparent governance structure guarantees open and verifiable financial transactions within a social organization. Audit mechanisms allow all parties to the So.Social protocols to build trust within their communities.',
  },
]

export default highLightsList;