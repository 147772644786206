import React from 'react';

interface IEmpty {
  height: string;
}

const Empty = ({height}: IEmpty) => {
  return <div style={{height}}>
  </div>
}

export default Empty;