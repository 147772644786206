import React, { useEffect, useRef, useState } from "react";
import cls from "classnames";
import { hasNewLine } from "@/utils";
import s from './index.module.less';

interface IFade {
  keyword: string;
  className?: string;
  time?: number;
  gap?: number;
  delay?: boolean;
}

const Fade = ({keyword, className, time, delay, gap = 30}: IFade) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const keys = keyword.split('');

  const obs = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        const loadingEntry = entries[0];
        if (loadingEntry.isIntersecting) {
          setShow(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '0px 0px -200px 0px', // 提前加载高度
      },
    )
    observer.observe(ref.current as unknown as Element);
  }

  useEffect(() => {
    if (time) {
      setTimeout(() => setShow(true), time);
    } else if (!delay) {
      obs();
    }
  }, [time, delay]);

  return <div ref={ref} className={cls({[s.fade]: show}, className)}>
    {keys.map((item, index) => <div
      className={cls(s.letter, {[s.block]: hasNewLine(item)})}
      key={item + index}
      style={{animationDelay: `${ (index + 1) * gap}ms`}}
    >
      {item.charCodeAt(0) === 32 ? '' : item}
    </div>)}
  </div>
}

export default Fade;
