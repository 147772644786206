import React, {useEffect, useState} from "react";
import { verifyLink } from "@/utils/api/risk";
import deniedVisitIcon from './images/denied.png'
import warnVisitIcon from './images/warning.png'
import './index.less'

export function VisitLink(props) {
    const query = new URLSearchParams(window.location.search)
    const link=query.get("link")
    const parentId=query.get("oid")?? '0'
    const parentType=query.get("otype")?? '0'
    const nobg=query.get("noBg")
    console.log('nobg', nobg)
    const [resp, setResp] = useState(null)
    const defaultContentHead = "An error occured and the visiting has been stopped. Please try again later."
    const RuleType = {
        Unknown        :0,
        Black          :1,
        White          :2,
        PopularUnknown :3,
    }
    
    useEffect(() => {
        verifyLink(link,parentId, parentType).then(res => {
            console.log('resp', res)
            const resp = res.data
            if(resp) {
                if(resp.ruleType === RuleType.White || resp.ruleType === RuleType.PopularUnknown) {
                    window.location.href = resp.link
                } else {
                    document.title = resp.pageTitle;
                    setResp({
                        ...resp,
                    })
                }
            }
        }).catch(()=> {
                setResp({ruleType: RuleType.Black, contentHead:defaultContentHead})
            }
        )
    }, [])

    document.getElementsByTagName('body')[0].style.backgroundColor = nobg ? 'transparent' : '';

    return (
        <div className={nobg ? '' : 'visitWrapper'}>
            {resp?.ruleType === RuleType.Unknown && <WarnVisitPage 
                link={resp.link} contentHead={resp.contentHead} visitButtonText={resp.visitButtonText}></WarnVisitPage>}
            {resp?.ruleType === RuleType.Black && <DeniedVisitPage contentHead={resp.contentHead} contentBody={resp.contentBody}></DeniedVisitPage>}
        </div>
    )
}

export function DeniedVisitPage(props) {
    const {contentHead, contentBody}=props
    return (
        <div>
            <div className='icon' style={{textAlign:"center"}}>
                <img src={deniedVisitIcon}></img>
            </div>
            <div className='deniedVisitContentTitle'>{contentHead}</div>
            <div className='deniedVisitContentBody'>
                {contentBody}
            </div>
        </div>
    )
}


export function WarnVisitPage(props) {
    const {link, contentHead, visitButtonText}=props
    let displayLink = link
    if(link?.length > 60) {
        displayLink = link.slice(0,60) + "..."
    }
    return (
        <div>
            <div className='icon' style={{textAlign:"center"}}>
                <img src={warnVisitIcon}></img>
            </div>
            <div className='warnVisitHeadText'>{contentHead}</div>
            <div className='warnVisitContentBodyText'>{displayLink}</div>
            <div className='visitButtonDiv clickable' onClick={()=>window.location.href = link}></div>
        </div>
    )
}