import React, { useRef, useEffect } from 'react';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { TextPlugin } from 'gsap/TextPlugin';
import { ScrollTrigger as GASPScrollTrigger } from 'gsap/ScrollTrigger';
import s from './index.module.less';
import { minSquarePointData, mosSquarePointData, boxData } from './data';
import avatarLeft1 from '../../images/avatar-left-1.png';
import avatarLeft2 from '../../images/avatar-left-2.png';
import avatarLeft3 from '../../images/avatar-left-3.png';
import avatarLeft4 from '../../images/avatar-left-4.png';
import avatarRight1 from '../../images/avatar-right-1.png';
import avatarRight2 from '../../images/avatar-right-2.png';
import avatarRight3 from '../../images/avatar-right-3.png';
import avatarRight4 from '../../images/avatar-right-4.png';

gsap.registerPlugin(useGSAP, TextPlugin, GASPScrollTrigger);

function HomeAnimation({ className }) {
  const container = useRef();
  const lineDone = useRef(false);
  function addLine(param) {
    const tmpTag = document.createElement("span");
    tmpTag.style.position = "absolute";
    tmpTag.className = param.className
    if (param.className == s.lineHorizontal) {
      tmpTag.style.width = `${parseInt(200 + Math.random() * 500)}px`
      tmpTag.style.left = `-${tmpTag.style.width}`;
    }
    if (param.className == s.lineRight) {
      tmpTag.style.height = `${parseInt((200 + Math.random() * 200))}px`
      tmpTag.style.top = `-${tmpTag.style.height}`;
    }
    if (param.y) {
      tmpTag.style.top = `${param.y}`;
    }
    if (param.x) {
      tmpTag.style.left = `${param.x}`;
    }
    document.getElementById('g-grid').appendChild(tmpTag);
    gsap.to(tmpTag, 10, {
      x: param.endX,
      y: param.endY,
      repeat: param.repeat ? -1 : 0,
      ease: "power1.inOut",
      delay: param.delay,
    });
  }
  function addSquare(param) {
    const img = document.createElement('img');
    img.src = param.imgSrc;
    img.style.position = 'absolute';
    img.className = param.className;
    var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - 200;
    var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 200;
    if (param.x) {
      if (parseInt(param.x.replace("px", "")) > viewportWidth) {
        return
      }
      img.style.left = param.x
    }
    if (param.y) {
      if (parseInt(param.y.replace("px", "")) > viewportHeight) {
        return
      }
      img.style.top = param.y
    }
    img.style.width = param.width
    if (param.height) {
      img.style.height = param.height
    }
    document.getElementById('g-grid').appendChild(img);
    const tl2 = gsap.timeline({
      delay: Math.random() * 20,
      repeat: -1,
      transformOrigin: 'center center',
      stagger: {
        amount: 1.5,
        grid: "auto",
        from: "center"
      }
    });
    if (param.className.includes("transparent-box")) {
      tl2.fromTo(img, { opacity: 0, }, { opacity: 1, duration: 2 })
        .to(img, { opacity: 0, duration: 2 })
        .repeatDelay(Math.random() * 20)
    } else {
      tl2.fromTo(img, { opacity: 0, scale: 0.5 }, { opacity: 1, scale: 1.5, duration: 2 })
        .to(img, { opacity: 0, scale: 0.5, duration: 2 })
        .repeatDelay(Math.random() * 10)
    }
  }
  function genLine() {
    const numbersSet = new Set();
    while (numbersSet.size < 20) {
      numbersSet.add(Math.floor(Math.random() * 100));
    }
    const horizontalNumbers = [...numbersSet]
    horizontalNumbers.forEach(n => {
      n % 2 == 0 && addLine({
        y: `${n}vw`,
        className: s.lineHorizontal,
        repeat: true,
        delay: Math.random() * 10,
        endX: "98vw",
      })
    })
    const verticalNumbersSet = new Set();
    while (verticalNumbersSet.size < 20) {
      verticalNumbersSet.add(Math.floor(Math.random() * 100));
    }
    const verticalNumbers = [...verticalNumbersSet]
    verticalNumbers.forEach(n => {
      n % 2 == 0 && addLine({
        x: `${n}vw`,
        className: s.lineRight,
        repeat: true,
        delay: Math.random() * 10,
        endY: "100vh",
      })
    })
    const verticalInitPointArr = [];
    verticalInitPointArr.forEach(item => {
      addLine(item)
    })
    const horizontalInitPointArr = []
    horizontalInitPointArr.forEach(item => {
      addLine(item)
    })
  }

  function genMinSquare() {
    const pointArray = minSquarePointData.map(item => ({
      ...item,
      x: roundToNearestFiftyWidth(item.x),
      y: roundToNearestFiftyHeight(item.y),
    }))
    pointArray.forEach(pointItem => {
      addSquare({
        imgSrc: pointItem.imgSrc,
        className: pointItem.className,
        x: pointItem.x,
        y: pointItem.y,
        width: pointItem.width,
      })
    })
  }

  function genMosSquare() {
    const pointArray = mosSquarePointData.map(item => ({
      ...item,
      x: roundToNearestFiftyWidth(item.x),
      y: roundToNearestFiftyHeight(item.y)
    }));
    pointArray.forEach(pointItem => {
      addSquare({
        imgSrc: pointItem.imgSrc,
        className: pointItem.className,
        x: pointItem.x,
        y: pointItem.y,
        width: pointItem.width,
      })
    })
  }

  function roundToNearestFiftyWidth(percentage) {
    return parseInt(percentage / 2) * 2 + "vw"
    // var pixels = (percentage / 100) * window.innerWidth;
    // return `${Math.round(pixels / 50) * 50}px`;
  }

  function roundToNearestFiftyHeight(percentage) {
    return parseInt(percentage / 2 * window.innerHeight / window.innerWidth) * 2 + "vw"
    // return percentage + "vh"
    // var a =  parseInt(percentage/4)*4 + "vw"
    // return `calc(${percentage}/4)*4 * var(--viewport-height)/var(--viewport-width))`
    // var pixels = (percentage / 100) * window.innerHeight;
    // return `${Math.round(pixels / 50) * 50}px`;
  }

  function genTransparentBox() {
    const pointArray = boxData.map(item => ({
      ...item,
      x: roundToNearestFiftyWidth(item.x),
      y: roundToNearestFiftyHeight(item.y),
    }))
    pointArray.forEach(pointItem => {
      let height = "50px"
      let width = "100px"
      if (pointItem.className.includes("transparent-box-1")) {
        width = "6vw"
        height = "6vw"
      }
      if (pointItem.className.includes("transparent-box-2")) {
        width = "6vw"
        height = "4vw"
      }
      if (pointItem.className.includes("transparent-box-3")) {
        width = "6vw"
        height = "2vw"
      }
      if (pointItem.className.includes("transparent-box-4")) {
        width = "4vw"
        height = "2vw"
      }
      if (pointItem.className.includes("transparent-box-5")) {
        width = "2vw"
        height = "2vw"
      }
      addSquare({
        imgSrc: pointItem.imgSrc,
        className: pointItem.className ? pointItem.className : 'transparent-box-1',
        x: pointItem.x,
        y: pointItem.y,
        width: width,
        height: height,
      })
    })
  }
  function genIcon() {
    const iconItemList = [
      { x: "3.51%", y: "55.08%", imgSrc: avatarLeft1, className: s.avatar, width: "3.14%" },
      { x: "17.34%", y: "60.37%", imgSrc: avatarLeft3, className: s.avatar, width: "3.77%" },
      { x: "7.86%", y: "75.60%", imgSrc: avatarLeft2, className: s.avatar, width: "5.89%" },
      { x: "24.93%", y: "75.82%", imgSrc: avatarLeft4, className: s.avatar, width: "3.18%" },
      { x: "66.81%", y: "85.97%", imgSrc: avatarRight1, className: s.avatar, width: "2.43%" },
      { x: "74.68%", y: "74.50%", imgSrc: avatarRight2, className: s.avatar, width: "3.56%" },
      { x: "81.93%", y: "60.97%", imgSrc: avatarRight3, className: s.avatar, width: "4.16%" },
      { x: "90.57%", y: "76.42%", imgSrc: avatarRight4, className: s.avatar, width: "5.09%" },
    ]
    iconItemList.forEach(iconItem => {
      addIcon(iconItem)
    })
  }
  function addIcon(iconItem) {
    const img = document.createElement('img');
    img.src = iconItem.imgSrc;
    img.style.position = 'absolute';
    img.className = iconItem.className;
    img.style.left = iconItem.x;
    img.style.top = iconItem.y;
    img.style.width = iconItem.width;
    //   img.style.width = "20%";
    document.getElementById('g-grid').appendChild(img);
  }

  useEffect(() => {
    if (lineDone.current) {
      return
    }
    lineDone.current = true;
    genLine();
    genMinSquare()
    genMosSquare()
    genTransparentBox()
    genIcon()
  }, []);
  return (
    <div className={className}>
      <div style={{ zIndex: 1 }}>
        <div style={{ backgroundColor: "black" }}>
          <div className={s.gGrid} id="g-grid"></div>
        </div>
      </div>
      <div ref={container.current}>
        <div id="demo">
        </div>
      </div>
    </div>
  );
}

export default HomeAnimation;
