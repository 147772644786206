import { useNavigate } from 'react-router-dom';
import Title from '../title';
import GridText from './gridText';
import help1 from './images/help1.png'
import help2 from './images/help2.png'
import help3 from './images/help3.jpg'
import s from './index.module.less';
import { useEffect } from 'react';

const HelpCenterDoc = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector(`html`)?.scrollTo(0, 0);
  }, [])

  const goTerms = () => navigate('/terms-of-service')
  const goPrivacy = () => navigate('/privacy-policy')
  const goCopyright = () => navigate('/copyright-policy')
  const goGuidelines = () => navigate('/community-guidelines')
  const goHelpCenter = () => navigate('/help-center')
  const goSubmitCopyRight = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSespPT6lVNsKX9qjz-bA7IfEEsk0nnGJvSKbnMZHqkt-RsDfA/viewform?pli=1')
  const goTradeNotice = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSctqySkdvCfdDxpkXkTUTfQmFR6IKDN56u34hibpC8rwQDGPA/viewform')

  return <div className={s.doc}>
    <Title className={s.h1} text="Help Center" />

    <p className={s.h2}>Basics</p>
    <p className={s.h3}>Your So.Social account</p>
    <p className={s.h4}>How Do I Get an Invitation Code?</p>
    <p>Ask a friend on So.Social to share their code. They can find their invitation code by:</p>
    <GridText texts={[
      <>Tapping "<span className={s.bold}>Profile</span>" on the top left of the Home page</>,
      <>Tapping "<span className={s.bold}>Invite</span>"</>,
    ]} />
    <p>If you don't have friends on So.Social, you can tap the "<span className={s.bold}>Skip</span>" button on signup.</p>

    <Title className={s.h4} text="How Do I Change my So.Social ID?" />
    <p>You are automatically assigned a So.Social ID on registration. You may set a unique So.Social ID in the account settings panel.</p>
    <p>Notice: You may only change your So.Social ID once. Once you set your So.Social, it cannot be changed!</p>
    <p>
      If you want to change your So.Social, please contact
      &nbsp;
      <a style={{display: 'inline'}} href='mailto: support@so.social' target='_blank' rel="noopener noreferrer">support@so.social</a>
    </p>
    <Title className={s.h4} text="I Forgot my Password" />
    <p>If you forgot your password, you can use your registered phone number or email to reset the password. </p>
    <p>To reset your password, navigate to the password settings page in your account</p>
    <p>If you forgot your password and are not logged in, you can tap "Forgot password?" in the "Login" page. </p>

    <Title className={s.h4} text="I Want to Recover a Deleted Account" />
    <p>If you delete your account by yourself, you can restore it within 30 days. </p>
    <p>To recover your account:</p>
    <GridText texts={[
      <>Tapping "<span className={s.bold}>Login</span>"</>,
      'Fill in your account and password, then follow the instructions to recover your account.'
    ]} />
    <p>If your account has been suspended, you will no longer be able to log in. You may appeal your suspension by contacting So.Social via email: &nbsp;
      {/* &nbsp; */}
      <a style={{display: 'inline'}} href='mailto: appeal@so.social' target='_blank' rel="noopener noreferrer">appeal@so.social</a>
    </p>

    <Title className={s.h3} text="Using So.Social" />
    <p className={s.h4}>What is an Admin/Co-Admin?</p>
    <p>An Admin is the owner of a Room/Group Chat and will be responsible for everything in the Room or the Chat. Co-Admins are assigned to help the admin manage the Circle/Room and are managed by the Admin. One Circle/Chat can add up to 20 Co-Admins.</p>
    <p className={s.h4}>Why am I not able to create multiple Circles or Rooms?</p>
    <p>To access more features and be able to create more Rooms and Circles, you will need to reach a higher user level.  You may view the requirements for advancing to the next level and your level progress by clicking on the icon next to your username in the settings panel.</p>
    <p className={s.h4}>Viewing Circle Financials</p>
    <p>The Circle Financials Page is where you can find all information regarding the contributions of your Circle members, and the progress of your token launch. Once your token has launched, you may also view its relevant statistics on this same panel. The Circle Financials panel is accessible through the "Circle Points" icon on the Circle homepage.</p>

    <p className={s.h3}>How do Circle Upgrades Work?</p>
    <p className={s.h4}>Upgrading allows you to:</p>
    <GridText texts={[
      'Launch your Circle Token and make Dex trades on that token',
      'send/receive a Genesis NFT (sent to those who have contributed to the community)'
    ]} />

    <p className={s.h4}>Upgrade Requirements:</p>
    <p>You may upgrade your circle if you meet the following requirements:</p>
    <GridText texts={[
      'The number of Circle members reaches the requirement mentioned',
      'The Admin/Co-Admin has completed the design of the Circle Token',
      'The Admin/Co-Admin has completed the design of the Genesis NFT',
      'The required number of Energy Cells has been collected'
    ]} />

    <p>A Circle is upgraded automatically when meeting these requirements.</p>
    <p>Energy Cell collection may only start when the first 3 requirements are met. If you have questions about the Energy Cell collection, or it is stopped or paused, please contact the Circle Admin. </p>
    <p>If the collection is stopped or paused, your boosted Energy Cells will be returned to you after a certain period of time.</p>

    <Title className={s.h3} text="What are Circle Milestones？" />
    <p>When your Circle reaches certain set targets like Circle size, members of the Circle can receive rewards. Each new milestone reached has two types of rewards: 1. Rewards given by the platform are shared among all members of the Circle, and 2. Additional SOP rewards are available in the Daily Rewards section, but reward drops are limited.</p>

    <Title className={s.h3} text="How are Contribution Scores Awarded?" />
    <p>You can contribute to your community through:</p>
    <p>Social Activities: Engage with your circle daily by interacting with fellow members and inviting new members to join.</p>
    <p>Content Contribution: Create high-quality content that adds positive value to your circle. Note that inappropriate content and behavior will negatively impact your contribution.</p>
    <p>Financial Contribution: Support your circle by helping the token launch, holding and staking community tokens, or providing liquidity to the token DEX LP.</p>
    <p>The Contribution Score shown on your Public Circle Membership Card is calculated based on your activity in the Circle over the previous 10 days. The distributed Daily Rewards are also based on your Contribution Score.</p>

    <Title className={s.h3} text="How Does Blocking Work?" />
    <p>If you’d like to cut off contact from someone for any reason, you can block them by going to their profile or visiting your user settings. </p>
    <p>Aside from certain exceptions, members you block won't be able to access your profile or see your post.
    Once you've blocked someone, a few things will happen:</p>
    <GridText texts={[
      'Your profile and content will be hidden to the person you blocked',
      'Blocked accounts and their posts will be hidden',
      "Blocked accounts won't be able to directly interact with you",
      'Exceptions:',
      "  Blocking someone won't kick them out of any group chats both of you are in at the time you block them. If you're in a group chat with someone you've blocked, you can still see each other in the group chat. ",
      "  If you block an Admin, they will still be able to see any posts you make in a Circle or Room they moderate."
    ]} />

    <Title className={s.h3} text="How do I Submit a Copyright/Trademark Infringement Notice" />
    <p>
      If you find an alleged infringement of your copyright/trademark, you can submit a <span onClick={goSubmitCopyRight} className={s.link}>Copyright</span> or <span onClick={goTradeNotice} className={s.link}>Trademark Infringement Notice</span>.
    </p>

    <Title className={s.h2} text="Chat Bubble Design Guidelines" />
    <p>For your Chat Bubble to be created successfully, So.Social requires that your artwork be your own original work and meet the Chat Bubble design requirements.</p>
    <p>Please check the design guidelines below:</p>
    <img src={help1} style={{ width: '12rem' }} alt="" />
    <p>File size: 180px * 162px, ≤ 1 MB</p>
    <p>File format: PNG/Webp</p>
    <p>Design tips: Chat Bubbles may stretch horizontally and vertically on messages of different lengths. To achieve the best effects, ensure your design does not touch the stretch points at each side, which are marked by the red axes.</p>
    <p>Note: Transparent designs are not allowed.</p>

    <p className={s.h3}>❌ Disallowed Designs:</p>
    <GridText texts={[
      'The Bubble is too small',
      'Illustrations and Designs cover the stretch points ',
      'The Text Color is unreadable on the Bubble',
    ]} />
    <p>To create a Chat Bubble for your Genesis NFT, aside from creating the Chat Bubble itself, you'll also need to consider the design of the Bubble Thumbnail. </p>

    <Title className={s.h3} text="Here are the design specs:" />

    <Title className={s.h4} text="Chat Bubble Thumbnail:" />

    <img src={help2} style={{ width: '4rem' }} alt="" />
    <p>File size: 1:1 (suggested size: 300px*300px), ≤ 5 MB</p>
    <p>File format: JPG/PNG/Webp/GIF</p>
    <p>Design tips: Put the bubble in the center of the cover with text inside. Add a background image which better fits your work.</p>

    <Title className={s.h4} text="Chat Bubble Detail Page:" />

    <img src={help3} style={{ width: '7rem' }} alt="" />
    <Title className={s.h4} text="Chat Bubble Demo Spec:" />
    <GridText texts={[
      'File size: 1:1 (suggested size: 300px*300px), ≤ 10 MB',
      'File format: PNG/Webp',
      `Design tips: The main entity should include the Chat Bubble, a simple slogan (like the "Hi" in the example, with the same font color you've selected as your customized font). Please make sure the bubble has a transparent background.`,
    ]} />

    <Title className={s.h4} text="Background Spec:" />
    <GridText texts={[
      'File size: 16:9, ≤ 10 MB',
      'File format: jpg/PNG/Webp/mp4',
      `Design tips: Make sure the style of the background fits the Chat Bubble`,
    ]} />

    <Title className={s.h2} text="User Guidelines" />
    <p>If you are looking for information about what is allowed or banned on So.Social, please check out our <span onClick={goGuidelines} className={s.link}>User Guidelines</span></p>

    <p>
        Didn't find what you were looking for or have more questions? Contact us at &nbsp;
      <a style={{display: 'inline'}} href='mailto: support@so.social' target='_blank' rel="noopener noreferrer">support@so.social</a>
    </p>









  </div>
}

export default HelpCenterDoc;
