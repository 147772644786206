import { useNavigate } from 'react-router-dom';
import Title from '../title';
import Contact from './contact';
import s from './index.module.less';
import { useEffect } from 'react';

const CopyrightDoc = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector(`html`)?.scrollTo(0, 0);
  }, [])

  const goTerms = () => navigate('/terms-of-service')
  const goPrivacy = () => navigate('/privacy-policy')
  const goCopyright = () => navigate('/copyright-policy')
  const goGuidelines = () => navigate('/community-guidelines')
  const goHelpCenter = () => navigate('/help-center')
  const goSubmitCopyRight = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSespPT6lVNsKX9qjz-bA7IfEEsk0nnGJvSKbnMZHqkt-RsDfA/viewform?pli=1')
  const goTradeNotice = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSctqySkdvCfdDxpkXkTUTfQmFR6IKDN56u34hibpC8rwQDGPA/viewform')

  return <div className={s.doc}>
    <Title className={s.title} text="Copyright Policy" />
    <div className={s.time}>Last updated: August 1st, 2024</div>
    <p>As a So.Social user, you are responsible for the content you create. So.Social ensures that the intellectual property rights of others are respected, and we expect you to do the same. So.Social’s <span onClick={goTerms} className={s.link}>Terms of Service</span> and <span onClick={goGuidelines} className={s.link}>User Guidelines</span> do not allow posting, sharing, or sending any content that violates or infringes upon someone else’s copyright, trademark or other intellectual property rights.</p>

    <Title className={s.h3} text="Copyright" />
    <p>Copyright is a legal right that protects original works of authorship (e.g., music, videos, etc.). Generally, copyright protects an original expression of an idea (e.g., the specific way a video or music is expressed or created) but does not protect underlying ideas or facts.</p>

    <Title className={s.h3} text="Copyright Infringement" />
    <p>We do not allow any content that infringes copyright. The use of copyrighted content of others without proper authorization or legally valid reason may constitute a violation of So.Social's policies.</p>
    <p>At the same time, not all unauthorized uses of copyrighted content constitute an infringement. In many countries, exceptions to copyright infringement allow the use of copyrighted works under certain circumstances without authorization. These include the fair use doctrine in the United States and permitted acts of fair dealing in the European Union (and other equivalent exceptions under applicable local laws in other countries).</p>

    <Title className={s.h3} text="Removal of Content; Suspension or Termination of Account" />
    <p>Any user content that infringes upon another person’s copyright may be removed. The account may be suspended or terminated for multiple copyright violations in connection with the use of So.Social Platforms, or other violations of the <span onClick={goTerms} className={s.link}>Terms of Service</span> and <span onClick={goGuidelines} className={s.link}>User Guidelines</span>. We reserve the right to refuse any account holder whose account was used for improper activities from opening a new account on So.Social’s site or app, or on a platform otherwise hosted by So.Social.</p>

    <Title className={s.h3} text="Copyright Infringement Notification" />
    <p>Contacting the user who is in violation of your copyright directly may resolve your complaint more quickly and in a way that is more beneficial to you, the user, and our community. You may also file a <span onClick={goSubmitCopyRight} className={s.link}>Copyright Infringement Notice</span> to request the removal of the alleged infringing content from our Platform.</p>
    <p>All complaints should contain the information requested in our online <span onClick={goSubmitCopyRight} className={s.link}>Copyright Infringement Notice</span> form. Failure to include necessary information may limit our ability to investigate your claims and may result in your complaint being denied.</p>
    <p>We may provide the account holder with your contact information, including the email address and the name of the copyright owner, and/or details of the complaint.</p>
    <p>Before submitting a notification, please be aware that intentionally submitting a misleading or fraudulent report may lead to liability for damages under section 512(f) of the United States Digital Millennium Copyright Act (DMCA) or similar laws as may be applicable in other countries.</p>
    <p>If you submit a report or infringement notification to us, we may contact you if we have additional questions about your report or notification. Please note that So.Social is not in a position to adjudicate disputes between third parties, and may not be able to remove the content or suspend the account you reported. As an alternative, you may want to contact the person who posted the content or owns the account to try to resolve your issue directly.</p>

    <Title className={s.h3} text="Trademark" />
    <p>A trademark is a word, symbol, slogan, design, or combination of any of the aforementioned that identifies the source of a product or service and distinguishes it from other products or services.</p>

    <Title className={s.h3} text="Trademark Infringement" />
    <p>Trademark laws prohibit trademark infringement, which is generally the unauthorized use of a trademark or service mark in connection with goods or services in a way that is likely to cause confusion, deception or mistake about the source, origin, sponsorship or affiliation of the associated goods and/or services.</p>
    <p>At the same time, the use of another’s trademark for purposes of accurately referencing, lawfully commenting, criticizing, parodying, or reviewing the trademark owner’s products or services, or for purposes of comparing them to other products or services, where the mark is not used to designate the user’s own goods or services or those of a third party, is generally not considered a violation of trademark policies. Likewise, it is generally permissible to make a fan page about a brand, even without the brand’s permission, provided that you do not claim to speak for or be associated with the brand or otherwise violate the brand’s intellectual property rights.</p>

    <Title className={s.h3} text="Removal of Content; Suspension or Termination of Account" />
    <p>Any content that violate another’s trademark rights may be taken down. Repeat violations of trademark rights in connection with the use of the So.Social site or app, or other violations of the <span onClick={goTerms} className={s.link}>Terms of Service</span> and <span onClick={goGuidelines} className={s.link}>User Guidelines</span> may result in the suspension or termination of the user account. We reserve the right to refuse any account holder whose account was used for improper activities from opening a new account on So.Social’s site or app, or on a platform otherwise hosted by So.Social.</p>

    <Title className={s.h3} text="Trademark Complaint and Notification" />
    <p>Contacting the user directly may resolve your complaint more quickly and in a way that is more beneficial to you, the user, and our community. You may also file a <span onClick={goTradeNotice} className={s.link}>Trademark Infringement Notice.</span></p>
    <p>All complaints should contain the information requested in our online Trademark Infringement Report form. Failure to include necessary information may limit our ability to deal with your claims and may result in your complaint being denied. We may provide the account holder with your contact information, including the email address and the name of the trademark owner, and/or details of the complaint, in accordance with our <span onClick={goTerms} className={s.link}>Terms of Service</span> and <span onClick={goPrivacy} className={s.link}>Privacy Policy</span>.</p>
    <p>Before submitting a notification, please be aware that intentionally submitting a misleading or fraudulent report may lead to liability for damages under laws as may be applicable in some countries.</p>
  </div>
}

export default CopyrightDoc;
