import React from "react";
import Title from "../title";
import { message } from 'antd';
import s from './mobile.module.less';
import Cta from "../animation/components/cta";
import { option } from "@/utils/constant";

const FirstView = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const comingsoon = () => {
    messageApi.open({
      className: s.success,
      duration: 3,
      icon: null,
      content: 'Coming soon...',
    });
  };

  const downApp = (key: keyof typeof option) => {
    const url = option[key] as string;
    if (url) {
      window.open(url)
    } else {
      comingsoon();
    }
  }

  return <div className={s.first}>
    {contextHolder}
    <Title className={s.title} text="Reinventing" />
    <Title className={s.title} text="Social Interactions" />
    <Title className={s.title} text="IN Web3" />
    <Title className={s.title} text="- Decentralized -" />
    <Title className={s.title} text="- Modular -" />
    <Title className={s.title} text="- AI Enabled -" />
    <div className={s.text}> 
      So.Social is a new web3 social ecosystem whose social protocols, infrastructure, and applications are poised to transform how social interactions are conducted in web3
    </div>
    <Cta keyword="LEARN MORE" className={s.learn} url='https://docs.so.social' />
    {/* <div className={s.join}>
      <Cta keyword="Join The Waitlist" url='https://forms.gle/bzxWLAdVKRzanMkm6' />
    </div> */}
    <div className={s.apps}>
      <div className={s.down} onClick={() => downApp('apk')} />
      <div className={s.google} onClick={() => downApp('googleplay')} />
      <div className={s.apple} onClick={() => downApp('appstore')} />
    </div>
  </div>
}

export default FirstView;
