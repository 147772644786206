import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import s from "./index.module.less";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={s.notfount}>
      <div className={s.img} />
      <div style={{ padding: '0 10px'}}>
        Oops! The page you are looking for cannot be found
      </div>
      <div>
        <Button type="primary" onClick={() => navigate('/')}>Back To Home</Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
