import { env } from "./env";

export const InvalidLinkInfo = "Invalid link.";
export const UnavailableLinkInfo = "This page does not exist anymore.";
export const PermissionDeniedInfo = "Access Denied.";
export const UnsupportedLinkInfo =
  "You need to open the app to view the content of this link.";

export const LinkStatus = {
  Invalid: 1,
  Unavailable: 2,
  AccessDenied: 3,
  Unsupported: 4,
};

export const siteKey = env === 'prod' ? '6LcO7h0qAAAAAA33l_NNXpTmHqtn9wexYUaAir_3' : '6LdprS0eAAAAALuGb-yJLYj55LTOTyMNrAwMU02i'

export const option = {
  scheme: {
    protocol: env === 'prod' ? "com.moonlaketech.ss.android" : 'spongess',
  },
  intent: {
    package: "com.moonlaketech.ss.android",
    scheme: "so.social",
  },
  hostname: env === 'prod' ? "so.social" : 'website.hypserspaces.com',
  // universal: {
  //     host: 'so.social',
  // },
  appstore:
    "https://testflight.apple.com/join/NWKExB1h",
  apk: 'https://sys.so.social/apks/app-sosocial-202408222220.apk',
  yingyongbao:
    "",
  googleplay:
    "https://play.google.com/store/apps/details?id=com.moonlaketech.ss.android",
  fallback: `${window.location.protocol}//${window.location.host}/download`,
  timeout: 5000,
};
