import React from "react";
import cls from "classnames";
import { isIos, isNative } from "@/utils/env";
import s from './index.module.less';

interface ITitle {
  text: string;
  className?: string;
  spark?: boolean;
}

const Title = ({text, className, spark = !isNative(), ...rest}: ITitle) => {
  if (isIos()) {
    return <div className={cls(s.title, className)} {...rest}>
      <span>{text}</span>
    </div>
  }
  return <div className={cls(s.title, className, {[s.spark]: spark})} {...rest}>
    <span>{text}</span>
    {
      spark
        ? <div className={s.aurora}>
          <div className={s.item} />
          <div className={s.item} />
          <div className={s.item} />
          <div className={s.item} />
        </div>
        : ''
    }
  </div>
}

export default Title;
