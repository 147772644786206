
import { Popover } from "antd";
import { useNavigate } from 'react-router-dom';
import menu from './images/menu.png';
import styPc from './index.module.less';
import styMobile from './mobile.module.less';
import { useState } from "react";
import classNames from "classnames";
import { isNative } from "@/utils/env";

const s = isNative() ? styMobile : styPc;

const Menu = () => {
  const [popopen, setPopOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpenChange = (newOpen: boolean) => {
    setPopOpen(newOpen);
  };

  const goLink = (url: string) => {
    navigate(url);
    setPopOpen(false);
  }

  return (
      <Popover
        trigger="click"
        placement="bottomRight"
        className={s.popover}
        open={popopen}
        onOpenChange={handleOpenChange}
        content={() => {
          return <div className={s.dropdown}>
            <div className={classNames(s.dot, s.topLeft)} />
            <div className={classNames(s.dot, s.topRight)} />
            <div className={classNames(s.dot, s.bottomLeft)} />
            <div className={classNames(s.dot, s.bottomRight)} />
            <div className={s.item} onClick={() => goLink('/')}>Home</div>
            <div className={s.item} onClick={() => goLink('/terms-of-service')}>Terms of Service</div>
            <div className={s.item} onClick={() => goLink('/privacy-policy')}>Privacy Policy</div>
            <div className={s.item} onClick={() => goLink('/copyright-policy')}>Copyright Policy</div>
            <div className={s.item} onClick={() => goLink('/community-guidelines')}>User Guidelines</div>
            <div className={s.item} onClick={() => goLink('/help-center')}>Help Center</div>
          </div>
        }}
        arrow={false}
      >
        <img className={s.menuImg} src={menu} alt="menu" />
      </Popover>
  );
};

export default Menu;
