import axios, { AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import JSONBigInt from 'json-bigint';
import { getNativeHeaders, env } from './env';
import { getDeviceId } from '.';

// 创建一个自定义类型，用于定义请求拦截器和响应拦截器的类型
interface CustomAxiosInterceptorManager<T> extends AxiosInterceptorManager<T> {
  use<R = T>(onFulfilled?: (value: T) => R | Promise<R>, onRejected?: (error: any) => any): number;
}

// 创建一个自定义 Axios 实例类型，扩展 AxiosInstance，并使用定义的拦截器类型
interface CustomAxiosInstance extends AxiosInstance {
  interceptors: {
    request: CustomAxiosInterceptorManager<AxiosRequestConfig>;
    response: CustomAxiosInterceptorManager<AxiosResponse>;
  };
}

const service = axios.create({
  baseURL: env === 'local' ? '/api' : '/',
  timeout: 60000,
}) as CustomAxiosInstance;

export const InvalidLinkCodeSet = new Set([
  1000030, 1000043, 1000068, 1000083, 6001, 1202, 4015, 2039, 4603, 4604, 2034
]);

service.interceptors.request.use(
  requestConfig => {
      requestConfig.headers = requestConfig.headers || {}
      const currentHeaders = getNativeHeaders();
      requestConfig.headers['rawDeviceId'] = currentHeaders?.['rawDeviceId'] || getDeviceId()
      requestConfig.headers['osType'] = currentHeaders?.['osType'] || 1;
      requestConfig.headers['deviceType'] = currentHeaders?.['deviceType'] || 1;
      requestConfig.headers['appType'] = currentHeaders?.['appType'] || 'z-web';

      const keys = [
        'appVersion',
        'sId',
        'nonce',
        'HJTRFS',
        'v',
        'carrierCountryCodes',
        'flavor',
        'expId',
        'user-agent'
      ]
      keys.forEach(item => {
        requestConfig.headers = requestConfig.headers || {};
        if (currentHeaders[item]) requestConfig.headers[item] = currentHeaders[item];
      })

      if (requestConfig.headers['Content-Type'] !== 'multipart/form-data') {
        requestConfig.transformRequest = [(data) => {
          return data ? JSONBigInt.stringify(data) : data
        }]
      }
      requestConfig.transformResponse = [(data) => {
        return JSONBigInt.parse(data)
      }]
      return requestConfig
  },
  err => {
    return Promise.reject(err)
  }
)

service.interceptors.response.use<AxiosResponse>(
  (resp) => {
    return resp
  },
  err => {
    if (!err.response) {
      message.error(`${err.code}: ${err.message}`, 3)
    } else if (err.response.status !== 200) {
      const { apiCode, apiMsg, debugMsg } = err.response.data
      if (InvalidLinkCodeSet.has(apiCode)) {
        return err.response
      }
      message.error({
        content: `E${apiCode}: ${apiMsg} info:${debugMsg || ''}`,
        duration: 3,
        key: 'kt'
      })
    }
    return Promise.reject(err)
  }
)

export default service;