import React, { useEffect, useState } from "react";
import cls from "classnames";
import s from './index.module.less';
import logo from 'src/components/animation/images/home-loading-logo.webp';

const Loading = () => {
  const [showLoading, setLoading] = useState(true);
  const [fald, setFald] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFald(true);
      setTimeout(() => setLoading(false), 1100);
    }, 3000);
  }, []);
  return showLoading ? <div className={cls(s.loading, {[s.fald]: fald})}>
    <img className={s.logo} src={logo} alt="loading" />
  </div> : null;
}

export default Loading;
