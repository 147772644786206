import square1 from '../../images/square-1.png';
import square2 from '../../images/square-2.png';
import square3 from '../../images/square-3.png';
import square4 from '../../images/square-4.png';
import square5 from '../../images/square-5.png';
import square6 from '../../images/square-6.png';
import squareS1 from '../../images/square-s-1.png';
import squareS2 from '../../images/square-s-2.png';
import squareS3 from '../../images/square-s-3.png';
import squareS4 from '../../images/square-s-4.png';
import squareS5 from '../../images/square-s-5.png';
import squareS6 from '../../images/square-s-6.png';
import squareS7 from '../../images/square-s-7.png';
import box1 from '../../images/transparent-box-1.png';
import box2 from '../../images/transparent-box-2.png';
import box3 from '../../images/transparent-box-3.png';
import box4 from '../../images/transparent-box-4.png';
import box5 from '../../images/transparent-box-5.png';

const minSquarePointData = [
  { x: 75.89, y: 19.53, imgSrc: square6, className: 'square-6',  width: '3.96%' },
  { x: 9.05, y: 68.71, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 83.12, y: 83.03, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 65.18, y: 78.12, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 53.97, y: 87.78, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 78.63, y: 54.19, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 78.65, y: 1.63, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 17.97, y: 6.50, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 62.94, y: 10.90, imgSrc: square5, className: 'square-5',  width: '2.99%' },
  { x: 22.46, y: 1.28, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 33.72, y: 20.60, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 2.16, y: 58.84, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 62.84, y: 63.53, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 47.12, y: 48.90, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 40.39, y: 78.05, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 11.19, y: 92.22, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 31.48, y: 87.64, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 60.63, y: 25.28, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 49.33, y: 6.18, imgSrc: square4, className: 'square-4',  width: '3.21%' },
  { x: 69.80, y: 49.89, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 22.63, y: 59.34, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 11.01, y: 45.03, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 9.16, y: 30.89, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 18.11, y: 73.76, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 0.17, y: 73.83, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 72.00, y: 83.49, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 58.53, y: 69.07, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 31.66, y: 54.65, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 42.83, y: 16.44, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 85.45, y: 21.45, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 11.42, y: 16.51, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 24.86, y: 16.41, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 69.79, y: 6.68, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 45.13, y: 11.65, imgSrc: square3, className: 'square-3',  width: '2.71%' },
  { x: 74.49, y: 45.49, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 67.78, y: 64.60, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 47.59, y: 83.77, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 56.53, y: 50.21, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 52.10, y: 69.46, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 40.89, y: 64.60, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 27.30, y: 59.84, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 36.38, y: 45.38, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 76.75, y: 93.32, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 94.73, y: 88.60, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 81.23, y: 78.84, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 45.33, y: 88.60, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 34.17, y: 69.46, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 22.90, y: 93.32, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 18.37, y: 83.84, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 7.17, y: 59.87, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 40.82, y: 31.32, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 0.45, y: 21.70, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 29.63, y: 11.90, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 63.32, y: 83.77, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 58.83, y: 7.07, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 52.08, y: 21.63, imgSrc: square2, className: 'square-2',  width: '2.19%' },
  { x: 88.41, y: 32.10, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 72.73, y: 60.87, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 36.80, y: 94.39, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 36.80, y: 79.94, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 27.79, y: 84.84, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 23.30, y: 27.31, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 14.35, y: 3.30, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 27.87, y: 8.24, imgSrc: square1, className: 'square-1',  width: '1.38%' },
  { x: 59.26, y: 22.44, imgSrc: square1, className: 'square-1',  width: '1.38%' },
];

const mosSquarePointData = [
  { x: 91.92, y: 53.20, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 2.01, y: 67.83, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 87.30, y: 34.62, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 24.54, y: 20.24, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 6.48, y: 10.55, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 15.51, y: 53.59, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 17.78, y: 10.69, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: -0.18, y: 29.47, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 93.96, y: 20.28, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 64.83, y: 10.65, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 76.12, y: 15.34, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 82.79, y: 73.01, className: 'square-s-7', imgSrc: squareS7 , width: '3.53%' },
  { x: 20.05, y: 1.42, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 13.30, y: 15.48, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 62.75, y: 1.21, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 62.85, y: 78.05, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 66.93, y: 68.25, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 78.47, y: 1.07, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 55.97, y: 15.41, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 35.70, y: 10.83, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 31.38, y: 48.69, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 46.99, y: 68.11, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 24.74, y: 63.49, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: -0.23, y: 78.02, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 13.27, y: 82.60, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 80.79, y: 82.95, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 73.99, y: 87.46, className: 'square-s-6', imgSrc: squareS6 , width: '3.33%' },
  { x: 1.83, y: 87.71, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 19.75, y: 87.68, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 31.01, y: 73.26, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 40.09, y: 92.37, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 82.62, y: 92.40, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 57.96, y: 87.57, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 71.38, y: 6.07, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 66.88, y: 25.39, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 93.78, y: 34.94, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 48.95, y: 25.39, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 53.43, y: 1.42, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 6.37, y: 28.59, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 8.63, y: 58.98, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 40.02, y: 6.29, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 28.82, y: 1.53, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 89.36, y: 44.67, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 37.75, y: 20.63, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: -0.45, y: 49.11, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 10.82, y: 34.80, className: 'square-s-5', imgSrc: squareS5 , width: '3.56%' },
  { x: 69.84, y: 73.65, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 74.34, y: 64.24, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 51.93, y: 83.35, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 38.43, y: 88.21, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 6.97, y: 45.03, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 29.51, y: 26.10, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 38.41, y: 49.86, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 20.37, y: 21.34, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 31.73, y: 16.41, className: 'square-s-4', imgSrc: squareS4 , width: '2.63%' },
  { x: 52.16, y: 45.77, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 13.93, y: 74.29, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 11.76, y: 64.81, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 13.77, y: 45.53, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 25.27, y: 55.04, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 61.09, y: 50.39, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 74.58, y: 50.25, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 85.82, y: 16.80, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 97.04, y: 64.99, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 38.68, y: 69.39, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 47.66, y: 93.32, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 76.82, y: 26.38, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 54.41, y: 21.56, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 97.02, y: 93.43, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 88.06, y: 83.84, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 0.42, y: 93.29, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 14.00, y: 88.57, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 97.02, y: 45.53, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 90.27, y: 31.21, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 81.31, y: 55.18, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 88.04, y: 50.39, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 96.99, y: 11.33, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 83.57, y: 31.07, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 67.84, y: 93.47, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 27.42, y: 93.36, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 49.87, y: 88.64, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 56.65, y: 64.63, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 88.01, y: 74.36, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 34.20, y: 83.81, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 58.86, y: 7.28, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 88.01, y: 21.56, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 0.43, y: 40.84, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 2.78, y: 16.76, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 47.64, y: 30.89, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 38.71, y: 2.38, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 63.29, y: 21.59, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 90.27, y: 59.91, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 45.39, y: 16.65, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 4.96, y: 83.84, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 27.42, y: 45.42, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 22.95, y: 79.05, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 16.20, y: 26.07, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 13.88, y: 7.03, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 11.69, y: 21.59, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 27.40, y: 16.73, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 52.16, y: 12.00, className: 'square-s-3', imgSrc: squareS3 , width: '2.19%' },
  { x: 5.12, y: 41.16, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 7.38, y: 65.09, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 16.31, y: 79.33, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 65.75, y: 84.23, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 56.80, y: 93.71, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 43.33, y: 84.30, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 2.94, y: 79.44, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 34.34, y: 79.47, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 76.97, y: 60.30, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 63.47, y: 69.82, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 7.35, y: 93.96, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 27.54, y: 31.50, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 23.05, y: 7.60, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 11.89, y: 26.81, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 29.88, y: 21.80, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 23.01, y: 50.64, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 29.86, y: 69.78, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 70.25, y: 2.73, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 81.53, y: 12.29, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 97.21, y: 55.40, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 94.98, y: 12.39, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 85.95, y: 26.85, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 72.48, y: 22.02, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 0.63, y: 12.32, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 56.78, y: 26.74, className: 'square-s-2', imgSrc: squareS2, width: '1.88%' },
  { x: 0.76, y: 70.17, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 36.70, y: 27.02, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 20.95, y: 31.92, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 3.01, y: 22.23, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 9.74, y: 50.99, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 18.72, y: 94.07, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 68.12, y: 50.99, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 30.00, y: 89.13, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 90.59, y: 65.34, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 95.09, y: 41.48, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 92.85, y: 60.62, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 79.35, y: 84.52, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 90.61, y: 94.14, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 97.31, y: 79.72, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 86.07, y: 84.48, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 92.82, y: 17.47, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 70.39, y: 27.10, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 50.17, y: 3.12, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 45.66, y: 27.13, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 20.95, y: 7.78, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 34.47, y: 12.54, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
  { x: 61.41, y: 17.47, className: 'square-s-1', imgSrc: squareS1, width: '1.60%' },
]

const boxData = [
  { x: 0.00, y: 47.87, className: 'transparent-box-5', imgSrc: box5, width: '2.23%' },
  { x: 77.93, y: 86.08, className: 'transparent-box-5', imgSrc: box5, width: '2.23%' },
  { x: 82.34, y: 76.60, className: 'transparent-box-5', imgSrc: box5, width: '2.23%' },
  { x: 57.65, y: 28.73, className: 'transparent-box-5', imgSrc: box5, width: '2.23%' },
  { x: 46.51, y: 67.08, className: 'transparent-box-5', imgSrc: box5, width: '2.23%' },
  { x: 95.51, y: 4.83, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 80.11, y: 9.62, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 86.90, y: 28.87, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 21.90, y: 62.25, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 15.05, y: 81.61, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 73.38, y: 0.04, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 59.89, y: 86.19, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 12.77, y: 4.97, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 68.87, y: 9.62, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 44.13, y: 24.04, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 71.23, y: 66.97, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 95.86, y: 34.13, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 59.93, y: 52.73, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 8.35, y: 43.22, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 12.94, y: 57.35, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 93.68, y: 57.67, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 0.00, y: 9.84, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 24.03, y: 0.00, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 64.48, y: 19.11, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 21.85, y: 28.73, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 87.00, y: 90.98, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 26.27, y: 76.53, className: 'transparent-box-4', imgSrc: box4, width: '2vw' },
  { x: 0.05, y: 95.28, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 0.00, y: 62.39, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 23.98, y: 91.19, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 68.91, y: 29.01, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 89.04, y: 47.87, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 28.37, y: 19.14, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 48.77, y: 0.00, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 46.43, y: 95.42, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 60.74, y: 67.37, className: 'transparent-box-3', imgSrc: box3, width: '6.72%' },
  { x: 24.11, y: 47.80, className: 'transparent-box-2', imgSrc: box2, width: '7.20%' },
  { x: 68.92, y: 90.55, className: 'transparent-box-2', imgSrc: box2, width: '7.20%' },
  { x: 91.29, y: 19.32, className: 'transparent-box-2', imgSrc: box2, width: '7.20%' },
  { x: 73.38, y: 52.77, className: 'transparent-box-2', imgSrc: box2, width: '7.20%' },
  { x: 30.76, y: 81.53, className: 'transparent-box-2', imgSrc: box2, width: '7.20%' },
  { x: 32.96, y: 0.00, className: 'transparent-box-1', imgSrc: box1, width: '6.77%' },
  { x: 51.06, y: 38.42, className: 'transparent-box-1', imgSrc: box1, width: '6.77%' },
  { x: 1.56, y: 23.72, className: 'transparent-box-1', imgSrc: box1, width: '6.77%' },
  { x: 53.18, y: 9.45, className: 'transparent-box-1', imgSrc: box1, width: '6.77%' },
]

export {
  minSquarePointData,
  mosSquarePointData,
  boxData
};
