import { ReactNode } from "react";
import s from "./index.module.less";

const GridText = ({ texts }: { texts: (string | ReactNode)[] }) => {
  return <>
    {
      texts.map((item, index) => (
        <div className={s.grid} key={index}>
          <div className={s.points} />
          <p>{item}</p>
        </div>
      ))
    }
  </>
};

export default GridText;
