import React, { useEffect, useRef, useState } from "react";
import cls from "classnames";
import Title from "../title";
import Empty from "../empty";
import highLightsList from "./hightlights";
import Cta from "../animation/components/cta";
import s from './index.module.less';

const HighLights = () => {
  const ref = useRef(null);
  const [queue, setQueue] = useState<number[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const loadingEntry = entries[0];
        if (loadingEntry.isIntersecting) {
          highLightsList.forEach((item, index) => {
            setTimeout(() => {
              setQueue(new Array(index + 1));
            }, index * 300)
          });
          observer.disconnect();
        }
      },
      {
        threshold: 0.2, // 提前加载高度
      },
    )
    observer.observe(ref.current as unknown as Element);
  }, []);

  return <div className={s.highLights}>
    <div className='radius'>
      <Empty height='0.6rem' />
      <Title text='Protocol Highlights' />
      <div className={s.wrapper} ref={ref}>
        {
          highLightsList.map((item, index) => <div
            key={item.title}
            className={cls(s.each, {[s.animation]: index < queue.length})}
          >
            <div className={s.image}>
              <img src={item.img} alt="HighLights" />
            </div>
            <div className={s.title}>
              {item.title}
            </div>
            <div className={s.text}>
              {item.text}
            </div>
          </div>)
        }
      </div>
      <div className={s.center}>
        <Cta keyword="VIEW THE SO.SOCIAL PROTOCOLS" className={s.view} url='https://docs.so.social' />
      </div>
    </div>
  </div>
}

export default HighLights;
