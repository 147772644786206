import React, { useEffect, useRef, useState } from "react";
import cls from "classnames";
import acList from "../acCard/acList"; 
import AcCard from "../acCard";
import Fade from "../animation/components/fade";
import animation1 from './animation/page3-animation-1.webp';
import animation2 from './animation/page3-animation-2.webp';
import animation3 from './animation/page3-animation-3.webp';
import animation4 from './animation/page3-animation-4.webp';
import animation5 from './animation/page3-animation-5.webp';
import s from './mobile.module.less';

const RealSocial = () => {
  const ref = useRef(null);
  const [queue, setQueue] = useState<number[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const loadingEntry = entries[0];
        if (loadingEntry.isIntersecting) {
          acList.forEach((item, index) => {
            setTimeout(() => {
              setQueue(new Array(index + 1));
            }, index * 1000)
          });
          observer.disconnect();
        }
      },
      {
        threshold: 0.3, // 提前加载高度
      },
    )
    observer.observe(ref.current as unknown as Element);
  }, []);

  return <div className={s.real}>
    <div className='radius-mobile'>
      <div className={s.anaimationTop}>
        <div className={s.left}>
          <img src={animation1} alt="animation" />
        </div>
        <div className={s.right}>
          <img src={animation2} alt="animation" />
        </div>
      </div>
      <Fade className={s.title} keyword={'For Real\rSocial Scenarios'} />
      <div className={s.text}>
        <span className={s.green}>It's not just theoretical: </span>
        <br />
        So.Social's protocols are designed
        <br />
        for practical use in real social
        <br />
        situations. Starting from the
        <br />
        bottom up, our system supports
        <br />
        social interactions between two
        <br />
        friends as much as multi-million
        <br />
        follower channels.
      </div>
      <div className={s.anaimationBottom}>
        <div className={s.left}>
          <img src={animation4} alt="animation" />
        </div>
        <div className={s.middle}>
          <img src={animation3} alt="animation" />
        </div>
        <div className={s.right}>
          <img src={animation5} alt="animation" />
        </div>
      </div>
    </div>
    <div className="radius-mobile">
      <div className={s.acList} ref={ref}>
        {
          acList.map(((item, index) => <div className={cls(s.each, {[s.animation]: index < queue.length})} key={item.title}>
            <AcCard {...item} />
          </div>))
        }
      </div>
    </div>
  </div>
}

export default RealSocial;
