import { useNavigate } from 'react-router-dom';
import Title from '../title';
import GridText from './gridText';
import s from './index.module.less';
import { useEffect } from 'react';

const GuidelinesDoc = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector(`html`)?.scrollTo(0, 0);
  }, [])

  const goTerms = () => navigate('/terms-of-service')
  const goPrivacy = () => navigate('/privacy-policy')
  const goCopyright = () => navigate('/copyright-policy')
  const goHelpCenter = () => navigate('/help-center')
  const goSubmitCopyRight = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSespPT6lVNsKX9qjz-bA7IfEEsk0nnGJvSKbnMZHqkt-RsDfA/viewform?pli=1')
  const goTradeNotice = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSctqySkdvCfdDxpkXkTUTfQmFR6IKDN56u34hibpC8rwQDGPA/viewform')

  return <div className={s.doc}>
    <Title className={s.title} text="User Guidelines" />
    <div className={s.time}>Last updated: August 1st, 2024</div>
    <p>The So.Social User Guidelines, which we update from time to time, are an important code of conduct for a safe community. Violation of these guidelines may result in your account and/or content being removed. In addition to these guidelines, users must be aware of and follow any local laws in their jurisdiction that govern their internet use. We reserve the right to monitor and report content to authorities, as permitted by applicable law.</p>
    <p>Our <span onClick={goTerms} className={s.link}>Terms of Service</span> and these User Guidelines exist to foster trust, respect, and a positive environment for everyone on our platform.</p>

    <Title className={s.h3} text="So.Social is NOT the place to post, share, or promote any of the following:" />
    <span className={s.h4}>Harmful and Dangerous Content:</span>
    <GridText texts={[
      'Harmful and Dangerous Content includes the promotion of terrorist organizations and organized crime, the use or sale of illegal weapons, bombs, narcotics, or other regulated goods, and the depiction or encouragement of self-harm and suicide.'
    ]} />
    <span className={s.h4}>Graphic or Shocking Content</span>
    <GridText texts={[
      'Graphic and shocking content includes the posting, sharing, or sending of any violent, graphic, or sensational content meant to shock, and also prohibits any content that incites others to commit acts of violence.'
    ]} />
    <span className={s.h4}>Discrimination or Hate Speech</span>
    <GridText texts={[
      'DO NOT post, share, or send any content that incites hatred against a group of people based on their race, ethnicity, religion, nationality, culture, disability, sexual orientation, gender, gender identity, age, or any other discrimination.'
    ]} />
    <span className={s.h4}>Nudity or Sexual Activity</span>
    <GridText texts={[
      "So.Social strictly prohibits any content that contains, promotes, or encourages sexual assault, sexual abuse, sexual exploitation, or sexual violence. So.Social also prohibits the solicitation of sex work or the advertising of one's own sexual services.",
      'Any sexually explicit content featuring minors or content that sexually exploits minors is prohibited.  So.Social treats child safety with the utmost seriousness, and any content involving minors that is potentially provocative or contains sexually suggestive acts is strictly prohibited.  Such content may be reported to applicable law enforcement agencies in your jurisdiction.'
    ]} />
    <span className={s.h4}>Impersonation, Spam, Scams, or other Misleading Content</span>
    <GridText texts={[
      "So.Social prohibits the impersonation of another person or organization as well as doxxing other people's personally identifiable information, including addresses, phone numbers, national ID numbers, and more.",
      'Using automated or manual means to spam Chats, Circles, or Rooms with any messages is prohibited. A strict zero-tolerance policy is also taken with content that is intended to defraud users, scam users, or constitutes phishing.',
      "Financial fraud, including fraud centered around So.Social's in-app wallet technology, is strictly prohibited."
    ]} />

    <span className={s.h4}>Intellectual Property</span>
    <p>Please see our <span onClick={goCopyright} className={s.link}>Copyright Policy</span> for more information</p>


    <span className={s.h4}>Other Malicious Activity</span>
    <GridText texts={[
      "Measures that interfere with So.Social's operations or hack its website or associated networks are restricted. Users are not allowed to distribute files or software that contain viruses, trojans, worms, logic bombs, or other technologically malicious material.",
      "DO NOT modify, adapt, translate, reverse engineer, disassemble, decompile, or create any derivative products based on So.Social, including any files, tables or documentation, nor attempt to regenerate any source code, algorithms, methods, or techniques embodied in So.Social."
    ]} />

    <p>
      If you see content that you believe violates any of the User Guidelines, please report it using the flag feature within So.Social. If you have further concerns, please contact &nbsp;
      <a style={{display: 'inline'}} href='mailto: support@so.social' target='_blank' rel="noopener noreferrer">support@so.social</a>
    </p>
  </div>
}

export default GuidelinesDoc;
