import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNative } from '@/utils/env';
import FirstView from 'src/components/firstView';
import RealSocial from 'src/components/realSocial';
import Empty from 'src/components/empty';
import HighLights from 'src/components/highlights';
import Preview from 'src/components/preview';
import TradingViewIntro from '../tradingview';
import News from 'src/components/news';
import Copyright from 'src/components/copyright';
import './index.less';

function PcApp() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isNative()) {
      navigate('/mobile');
    }
  }, [navigate])

  return (
    <div className="App">
      <FirstView />
      <RealSocial />
      <Empty height='1.3rem' />
      <HighLights />
      <Empty height='1.3rem' />
      <Preview />
      <TradingViewIntro />
      <News />
      <Copyright />
    </div>
  );
}

export default PcApp;
