import React from 'react';
import { isNative } from '@/utils/env';
import stylePc from './index.module.less';
import styleMobile from './mobile.module.less';

const s = isNative() ? styleMobile : stylePc;

interface IAcCard {
  img: string;
  title: string;
  text: string;
}

const AcCard = ({img, title, text}: IAcCard) => {
  return <div className={s.wrapper}>
    <div className={s.img} style={{ backgroundImage: `url(${img})`}}>
    </div>
    <div className={s.text}>
      {title}
      <br />
      {text}
    </div>
  </div>
}

export default AcCard;