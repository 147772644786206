import React, { useEffect, useRef, useState } from "react";
import tv from './tv.png';
import s from './index.module.less';

const TradingViewIntro = () => {


  return <div className={s.tv}>
    <div className={'radius'}> 
      <div className={s.content}>
        <img src={tv} alt="" />
        <div>
          <div className={s.title}>TradingView Technology</div>
          <div className={s.desc}>
          Sobit Tech utilizes TradingView technology to display advanced price charts. TradingView is an advanced platform created for traders and investors, offering web browser, desktop software, and mobile application support. This platform provides users with real-time data, including Dow Futures and <a target="_blank" href="https://www.tradingview.com/symbols/BTCUSD/">BTC USD Charts</a>, as well as recent financial news, comprehensive financial reports, a stock screener, and an economic calendar.
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default TradingViewIntro;
