import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { isNative } from './utils/env';
import { setRemUnit } from './utils/dom';
import './index.css';
import './index.less';
import Loading from './components/loading';
import Header from './components/header';
import Pc from 'src/components/pc';
import Mobile from 'src/components/mobile';
import DocsTerms from 'src/components/docs/terms';
import PrivacyDoc from './components/docs/privacy';
import CopyrightDoc from './components/docs/copyright';
import GuidelinesDoc from './components/docs/guidelines';
import HelpCenterDoc from './components/docs/help';
import { VisitLink } from './components/visitLink';
import NotFoundPage from './components/404';
import reportWebVitals from './reportWebVitals';
import { env } from './utils/env';

// const Share = lazy(() => import('./components/share'));
const Download = lazy(() => import('./components/download'));
const Identity = lazy(() => import('./components/identity'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.onload = () => {
  setRemUnit();
  if (!isNative()) {
    // 在页面加载时和窗口大小变化时触发计算和设置字体大小
    window.addEventListener('DOMContentLoaded', setRemUnit);
    window.addEventListener('resize', setRemUnit);
  }
}

const invalidUrl = ["/identify", "/verify-link"];
const hideAll = invalidUrl.includes(window.location.pathname);

root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      {
        env !== 'local' && !hideAll && <Loading />
      }
      {
        !hideAll && <Header />
      }
      <Routes>
        <Route path="/" element={<Pc/>} />
        <Route path="/mobile" element={<Mobile/>} />
        <Route path="/s" element={<Pc/>} />
        <Route path="/s/:shareType/:shareId" element={<Pc/>} />
        <Route path="/s/:shareType" element={<Pc/>} />
        <Route path="/download" element={<Download/>} />
        <Route path="/identify" element={<Identity/>} />
        <Route path="/terms-of-service" element={<DocsTerms/>} />
        <Route path="/privacy-policy" element={<PrivacyDoc/>} />
        <Route path="/copyright-policy" element={<CopyrightDoc/>} />
        <Route path="/community-guidelines" element={<GuidelinesDoc/>} />
        <Route path="/help-center" element={<HelpCenterDoc/>} />
        <Route path="/verify-link" element={<VisitLink />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
