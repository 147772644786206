import Title from '../title';
import s from './index.module.less';

const Contact = ({attention = 'Terms of Service'}) => {
  return <>
    <Title className={s.h3} text="Contact" />
    <p>Questions, comments, and requests regarding these Terms of Service should be submitted via:</p>
    <p>
      Email Address: &nbsp;
      <a href='mailto: legal@so.social' target='_blank' rel="noopener noreferrer">
        legal@so.social
      </a>
    </p>
    <p>
      Attention: {attention}
    </p>
  </>
}

export default Contact;
