import wallet from "./images//wallet.png";
import interact from "./images//interact.png";
import staking from "./images//staking.png";
import governed from "./images//governed.png";
import { isNative } from "@/utils/env";

const previewList = [
  {
    img: wallet,
    title: 'Built-In Web3 Wallet',
    left: true
  },
  {
    img: interact,
    title: isNative() ? 'Interact with Assets \r - Socially' : 'Interact with Assets - Socially',
  },
  {
    img: staking,
    title: isNative() ? 'Communities Governed by\rReal Stakeholders' : 'Communities Governed by\rReal Stakeholders',
    left: true
  },
  {
    img: governed,
    title: isNative() ? 'Earnings by Staking \r in Communities' : 'Earnings by Staking in\rCommunities',
  },
]

export default previewList;