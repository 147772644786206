import CallApp from "callapp-lib";
import { option } from "./constant";
import { IIcon } from "./types/share";

const hasNewLine = (str: string) => {
  var regex = /[\r\n]+/;
  return regex.test(str);
};

const getQuery = (key: string): string | null => {
  const params = new URLSearchParams(window.location.search); // 获取地址栏参数
  const paramValue = params.get(key);
  return paramValue;
};

const jumpToStore = () => {
  const userAgent = navigator.userAgent;
  console.log(userAgent);
  if (
    userAgent.indexOf("Windows") > -1 ||
    userAgent.indexOf("Macintosh") > -1
  ) {
    window.location.href = "/download";
  } else if (
    userAgent.indexOf("Android") > -1 ||
    userAgent.indexOf("Adr") > -1
  ) {
    window.location.href = option.googleplay;
  } else if (
    userAgent.indexOf("like Mac OS X") > -1 ||
    userAgent.indexOf("iPhone") > -1
  ) {
    window.location.href = option.appstore;
  } else {
    window.location.href = "/download";
  }
};

const downloadApp = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Macintosh") > -1 || userAgent.indexOf("iPhone") > -1) {
    window.location.href = option.appstore;
  } else if (
    userAgent.indexOf("Android") > -1 ||
    userAgent.indexOf("Adr") > -1
  ) {
    window.location.href = option.googleplay;
  } else {
    window.location.href = option.googleplay;
  }
};

/**
 * 随机获取一个[min, max]的正整数
 * @param min 最小值，默认0
 * @param max 最大值
 * @returns number
 */
const getRandomNumber = (min = 0, max: number): number => {
  return Math.floor(Math.random() * (max + 1 - min) + min);
};

/**
 * 获取一个指定长度的随机字符串
 * @param length
 * @returns
 */
const getRandomString = (length: number): string => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i += 1)
    result += chars[getRandomNumber(0, chars.length)];
  return result;
};

const getDeviceId = () => {
  const deviceId = localStorage.getItem("deviceId");
  if (deviceId) return deviceId;
  const key = getRandomString(40);
  localStorage.setItem("deviceId", key);
  return key;
};


const genDeepLink = (objectType: number, objectId: string): string => {
  let deepLink;
  switch (objectType) {
    case 1:
      deepLink = "chat";
      break;
    case 2:
      deepLink = "blog";
      break;
    case 4:
      deepLink = "user";
      break;
    case 5:
      deepLink = "circle";
      break;
    case 55:
      deepLink = "merch";
      break;
    case 56:
      deepLink = "store";
      break;
    case 53:
      deepLink = "nft";
      break;
    case 67:
      deepLink = "gift-box";
      break;
  }
  deepLink = deepLink + "/" + objectId.toString();
  return deepLink;
};

const openOurApp = (path: string, objectType: number, objectId: string) => {
  const call = new CallApp(option);
  call.generateScheme = () => {
    let deepLink = genDeepLink(objectType, objectId);
    console.log(deepLink);
    return `${option.scheme.protocol}://${deepLink}`
  };
  call.generateUniversalLink = call.generateScheme;
  const cb = () =>
    (window.location.href = `${window.location.protocol}//${window.location.host}/download`);
  console.log("openOurApp, path:", path);
  let cfgPath = path;
  if (path.startsWith("/")) {
    cfgPath = path.substring(1);
  }
  call.open({ path: cfgPath, callback: cb });
};

const getMediaThumbnailImageUrl = (media: IIcon) => {
  if (media && media.resourceList) {
    for (let i = 0; i < media.resourceList.length; i++) {
      if (media.resourceList[i].thumbnail) {
        return media.resourceList[i].url;
      }
    }
  }
  return "";
};

const getMediaImageUrl = (media: IIcon) => {
  if (media && media.resourceList) {
    for (let i = 0; i < media.resourceList.length; i++) {
      if (media.resourceList[i]) {
        return media.resourceList[i].url;
      }
    }
    // return media.resourceList[media.resourceList.length-1].url
  }
  return "";
};

const isAdminUser = (uid: number) => uid >= 0 && uid <= 1000;

const openOurAppWithInfo = (path: string, objectType: number, objectId: string) => {
  const call = new CallApp(option)
  call.generateScheme = () => {
      let deepLink = genDeepLink(objectType, objectId)
      console.log(deepLink)
      return `${option.scheme.protocol}://${deepLink}`
  }
  call.generateUniversalLink = call.generateScheme
  const cb = () => window.location.href = `${window.location.protocol}//${window.location.host}/download?info=nft`
  let cfgPath = path
  if (path.startsWith('/')) {
      cfgPath = path.substring(1)
  }
  call.open({ path: cfgPath, callback: cb })
}

export {
  hasNewLine,
  downloadApp,
  getRandomNumber,
  getRandomString,
  getQuery,
  jumpToStore,
  genDeepLink,
  openOurApp,
  openOurAppWithInfo,
  getMediaThumbnailImageUrl,
  getMediaImageUrl,
  getDeviceId,
  isAdminUser
};
