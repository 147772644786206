import { useNavigate } from 'react-router-dom';
import Title from '../title';
import Contact from './contact';
import GridText from './gridText';
import s from './index.module.less';
import { useEffect } from 'react';

const TermsDoc = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector(`html`)?.scrollTo(0, 0);
  }, [])

  const goTerms = () => navigate('/terms-of-service')
  const goPrivacy = () => navigate('/privacy-policy')
  const goCopyright = () => navigate('/copyright-policy')
  const goGuidelines = () => navigate('/community-guidelines')
  const goHelpCenter = () => navigate('/help-center')
  const goSubmitCopyRight = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSespPT6lVNsKX9qjz-bA7IfEEsk0nnGJvSKbnMZHqkt-RsDfA/viewform?pli=1')
  const goTradeNotice = () => window.open('https://docs.google.com/forms/d/e/1FAIpQLSctqySkdvCfdDxpkXkTUTfQmFR6IKDN56u34hibpC8rwQDGPA/viewform')

  const gridText = [
    'access or use the Services if you are not fully able and legally competent to agree to these Terms;',
    'make unauthorized copies, modify, adapt, translate, reverse engineer, disassemble, decompile, or otherwise create any derivative works of the Services or any content included therein, including any files, tables, or documentation (or any portion thereof) or determine or attempt to determine any source code, algorithms, methods, or techniques embodied by the Services or any derivative works thereof;',
    'distribute, license, transfer, or sell, in whole or in part, any of the Services or any derivative works thereof;',
    'market, rent, or lease the Services for a fee or charge, or use the Services to advertise or perform any commercial solicitation;',
    'use the Services, without our express written consent, for any commercial or unauthorized purpose, including communicating or facilitating any commercial advertisement or solicitation or spamming;',
    'interfere with or attempt to interfere with the proper working of the Services, disrupt our website or any networks connected to the Services, or bypass any measures we may use to prevent or restrict access to the Services;',
    'incorporate the Services or any portion thereof into any other program or product. In such cases, we reserve the right to refuse service, terminate accounts, or limit access to the Services under our sole discretion;',
    'use automated scripts to collect information from or otherwise interact with the Services;',
    'impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with any person or entity, including giving the impression that any content you upload, post, transmit, distribute, or otherwise make available emanates from the Services;',
    'intimidate or harass another, or promote sexually explicit material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;',
    'use or attempt to use another’s account, service, or system without authorization from So.Social, or create a false identity on the Services;',
    'use the Services in a manner that may create a conflict of interest or undermine the purposes of the Services, such as trading reviews with other users or writing or soliciting fake reviews;',
    'use the Services to upload, transmit, distribute, store or otherwise make available in any way: files that contain viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful; ',
    'any unsolicited or unauthorized advertising, solicitations, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other prohibited form of solicitation; ',
    'any private information of any third party, including addresses, phone numbers, email addresses, numbers and/or features of a personal identity document (e.g., National Insurance numbers, passport numbers), or credit card numbers;',
    'any material which does or may infringe any copyright, trademark, or other intellectual property or privacy rights of any other person;',
    'any material which is defamatory of any person, obscene, offensive, pornographic, hateful, or inflammatory; ',
    'any material that would constitute, encourage, or provide instructions for a criminal offence, dangerous activities, or self-harm; ',
    'any material that is deliberately designed to provoke or antagonise people, especially trolling and bullying, or is intended to harass, harm, hurt, scare, distress, embarrass, or upset people; ',
    'any material that contains a threat of any kind, including threats of physical violence; ',
    'any material that is racist or discriminatory, including discrimination on the basis of someone’s race, religion, age, gender, disability, or sexuality; ',
    'any answers, responses, comments, opinions, analysis, or recommendations that you are not properly licensed or otherwise qualified to provide; or ',
    'material that, in the sole judgment of So.Social, is objectionable or which restricts or inhibits any other person from using the Services, or which may expose So.Social, SoBit Tech, the Services, or its users to any harm or liability of any type.',
  ]

  const gridText2 = [
    'any negligent acts, omissions, or wilful misconduct by you;',
    'your access to and use of the Services;',
    'the uploading or submission of Content to the Services by you;',
    'any breach of these Terms by you; ',
    'your violation of any law or of any rights of any third party.',
  ]
  return <div className={s.doc}>
    <Title className={s.title} text="Terms of Service" />
    <div className={s.time}>Last updated: August 1st, 2024</div>

    <Title className={s.h3} text="1. Your Relationship With Us" />
    <p>Welcome to So.Social (hereinafter referred to as the “Platform”), an application owned and licensed by SOBIT PTE. LTD. in Singapore (collectively such entities will be referred to as “So.Social”, "SoBit Tech", “we”, or “us”).</p>
    <p>You are reading the Terms of Service (hereinafter the “Terms”), which govern the relationship and serve as an agreement between you and us and set forth the terms and conditions by which you may access and use the Platform and our related websites, services, applications, products, and content (collectively, the “Services”). Access to certain Services or features of the Services (such as, by way of example and not limited to, the ability to submit or share User Content (defined below)) may be subject to age restrictions and not available to all users of the Services. Our Services are provided for private, non-commercial use. For purposes of these Terms, “you” and “your” refers to you as a user of the Services.</p>
    <p>The Terms form a legally binding agreement between you and us. Please take the time to read them carefully.</p>
    <p>Please take a look at So.Social’s <span onClick={goPrivacy} className={s.link}>Privacy Policy</span> too—it explains how we collect, use, and share information about you when you access or use our Services.</p>

    <Title className={s.h3} text="2. Accepting the Terms" />
    <p>By accessing or using our Services, you confirm that you are forming a binding contract with So.Social, that you accept these Terms, and that you agree to comply with them. Your access to and use of our Services is also subject to our <span onClick={goPrivacy} className={s.link}>Privacy Policy</span> and <span onClick={goGuidelines} className={s.link}>User Guidelines</span>, the terms of which can be found directly on the Platform, or where the Platform is made available for download, on your mobile device’s applicable app store, and are incorporated herein by reference. By using the Services, you consent to the terms of the Terms of Service.</p>
    <p>If you are accessing or using the Services on behalf of a business or entity, then (a) “you” and “your” includes you and that business or entity, (b) you represent and warrant that you are an authorized representative of the business or entity with the authority to bind the entity to these Terms, and that you agree to these Terms on the entity’s behalf, and (c) your business or entity is legally and financially responsible for your access or use of the Services as well as for the access or use of your account by others affiliated with your entity, including any employees, agents, or contractors.</p>
    <p>You can accept the Terms by accessing or using our Services. You understand and agree that your access or use of the Services constitutes acceptance of the Terms from that point onwards.</p>
    <p>You should print off or save a local copy of the Terms for your records.</p>

    <Title className={s.h3} text="3. Changes to the Terms" />
    <p>We reserve the right to amend these Terms, for instance when we update the functionality of our Services, or when there are regulatory changes. We will use commercially reasonable efforts to generally notify all users of any material changes to these Terms, such as through a notice on our Platform, however, you should look at the Terms regularly to check for such changes. We will also update the “Last Updated” date at the top of these Terms, which will reflect the effective date of such Terms. Your continued access or use of the Services after the date of the updated Terms constitutes your acceptance of the new Terms. If you do not agree to the new Terms, you must stop accessing or using the Services.</p>
  
    <Title className={s.h3} text="4. Your Account with Us" />
    <p>To access or use some of our Services, you must create an account with us. When you create this account, you must provide accurate and up-to-date information. It is important that you maintain and promptly update your details and any other information you provide to us, to keep such information current and complete.</p>
    <p>It is important that you keep your account password confidential and that you do not disclose it to any third party. If you know or suspect that any third party knows your password or has accessed your account, you can reset your password by receiving a password recovery SMS via your registered phone number or receiving a password recovery email via your registered email address. Promptly notify us of any breaches of your account at: <a style={{display: 'inline'}} href='mailto: support@so.social' target='_blank' rel="noopener noreferrer">support@so.social</a>.</p>
    <p>You agree that you are solely responsible (to us and to others) for the activity that occurs on your account.</p>
    <p>We reserve the right to disable your user account at any time, including, but not limited to, failing to comply with any of the provisions of these Terms, or if activities occur on your account which, in our sole discretion, would or might cause damage to or impair the Services or infringe or violate any third party rights, or violate any applicable laws or regulations.</p>
    <p>If you no longer want to use our Services, and would like your account deleted, please go to App Settings/Delete Account. If you are having issues deleting your account, please contact us via <a style={{display: 'inline'}} href='mailto: support@so.social' target='_blank' rel="noopener noreferrer">support@so.social</a>. A deleted account may be reactivated or restored for up to 30 days after its deletion, but once the deletion is permanent, you will not be able to retrieve any of the content or information you may have added to your account.</p>
  
    <Title className={s.h3} text="5. Your Access to and Use of Our Services" />
    <p>Your access to and use of the Services is subject to these Terms and all applicable laws and regulations. You may not:</p>
    <GridText texts={gridText} />
    <p>In addition to the above, your access to and use of the Services must, at all times, be compliant with our <span onClick={goGuidelines} className={s.link}>User Guidelines</span>.</p>
    <p>We reserve the right to, at any time and without prior notice, remove or disable access to content at our discretion for any reason or no reason. Some of the reasons we may remove or disable access to content may include finding the content objectionable, in violation of these Terms or of our <span onClick={goGuidelines} className={s.link}>User Guidelines</span>, or otherwise harmful to the Services or our users. Our automated systems may analyze your content to provide you personally relevant product features, such as customized search results, and content recommendations. This analysis occurs as the content is sent, received, and as it is stored.</p>
    
    <Title className={s.h3} text="6. Intellectual Property Rights" />
    <p>So.Social respects the intellectual property of others and requires that users of our Services do the same. We have a policy that includes the removal of any infringing materials from the Services and for the termination, in appropriate circumstances, of users of our Services who are repeat infringers. If you believe that anything on our Services infringes on a copyright that you own or control, you can submit a copyright notice <span onClick={goSubmitCopyRight} className={s.link}>here</span>, or trademark notice <span onClick={goTradeNotice} className={s.link}>here</span>.</p>
    <p>Also, please note that if you knowingly misrepresent that any activity or material on our Service is infringing of a trademark or copyright, you may be liable to So.Social for certain costs and damages.</p>
    <p>If we remove Your Content in response to a copyright or trademark notice, we will notify you via So.Social's private messaging system. If you believe Your Content was wrongly removed due to a mistake or misidentification, you can send a notification to us at <a style={{display: 'inline'}} href='mailto: support@so.social' target='_blank' rel="noopener noreferrer">support@so.social</a>.</p>

    <Title className={s.h3} text="7. Content" />
    <p>The Services may contain information, text, links, graphics, photos, videos, or other materials (“Content”), including Content created with or submitted to the Services by you or through your Account (“Your Content”). We take no responsibility for and we do not expressly or implicitly endorse any of Your Content.</p>
    <p>By submitting Your Content to the Services, you represent and warrant that you have all rights, power, and authority necessary to grant the rights to Your Content contained within these Terms. Because you alone are responsible for Your Content, you may expose yourself to liability if you post or share Content without all necessary rights.</p>
    <p>You retain any ownership rights you have in Your Content, but you grant So.Social the following license to use that Content:</p>
    <p>When Your Content is created with or submitted to the Services, you grant us a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, transferable, and sublicensable license to use, copy, modify, adapt, prepare derivative works from, distribute, perform, and display Your Content and any name, username, voice, or likeness provided in connection with Your Content in all media formats and channels now known or later developed. This license includes the right for us to make Your Content available for syndication, broadcast, distribution, or publication by other companies, organizations, or individuals who partner with So.Social. You also agree that we may remove metadata associated with Your Content, and you irrevocably waive any claims and assertions of moral rights or attribution with respect to Your Content.</p>
    <p>Any ideas, suggestions, and feedback about So.Social or our Services that you provide to us are entirely voluntary, and you agree that So.Social may use such ideas, suggestions, and feedback without compensation or obligation to you.</p>
    <p>Although we have no obligation to screen, edit, or monitor Your Content, we may, under our sole discretion, delete or remove Your Content at any time and for any reason, including for a violation of these Terms, a violation of our policies (See Term 5. Your Access to and Use of Our Services), or if you otherwise create liability for us.</p>

    <Title className={s.h3} text="8. Indemnity" />
    <p>You agree to defend, indemnify, and hold harmless So.Social, its parents, subsidiaries, and affiliates, and each of their respective officers, directors, employees, agents, and advisors from any and all claims, liabilities, costs, and expenses, including, but not limited to, attorneys’ fees and expenses, arising out of a breach by you or any user of your account of these Terms or arising out of a breach of your obligations, representation, and warranties under these Terms.</p>
    <p>All actions you take related to our Platform and all information you post on our Platform remain your responsibility. Therefore, you agree to indemnify, defend, release, and hold us, and our partners, licensors, affiliates, contractors, officers, directors, employees, representatives, and agents, harmless from and against any third party claims, damages (actual and/or consequential), actions, proceedings, demands, losses, liabilities, costs, and expenses (including reasonable legal fees) suffered or reasonably incurred by us arising as a result of, or in connection with:</p>
    <GridText texts={gridText2} />
    <p>We retain the exclusive right to settle, compromise, and pay any and all claims or causes of action which are brought against us without your prior consent. If we ask, you will co-operate fully and reasonably as required by us in the defence of any relevant claim.</p>
    <p>The foregoing provision does not require you to indemnify So.Social for any unconscionable commercial practice or any fraud, deception, false promise, misrepresentation or concealment, or suppression or omission of any material fact in connection with the Services.</p>

    <Title className={s.h3} text="9. Payment Terms" />
    <p>This section of the Terms of Service (hereinafter, “Payment Terms”) regarding all manner of payment services (hereinafter, “Payment Services”) provided by So.Social constitute a legally binding contract between us and the entity or sole proprietor on whose behalf a So.Social account is created (“you”) to receive certain mobile and technology-enabled payment services offered by So.Social to you through the So.Social websites (including without limitation so.social and/or related websites), as well as the So.Social mobile application, including without limitation, all services operated by the Platform.</p>
    <p className={s.h4}>Third Party Providers</p>
    <p>Terms and Service</p>
    <p>Certain functionalities available with the Payment Services will be provided by third party providers ("Third Party Providers") and will require that you enter into ancillary agreements with third parties directly in order to enable or access such functionality. ("Third Party Services")  For example, you may be required to enter into a payment processing agreement with Payment Processors including, but not limited to, PagSmile, Google Play, and Apple, in order to accept credit and debit card payments.</p>
    <p>Third Party Providers, and not So.Social, provide the Third Party Services to you. You are required to agree to any and all Third Party Terms order to receive the Payment Services. You must not use the Payment Services for any activity prohibited by applicable law or the Third Party Terms. You are responsible for ensuring the information in your Account Profile is accurate and complete at all times.</p>
    <p>Third Party Permissions</p>
    <p>You hereby authorize So.Social to share any information you provide to us with the Bank, Payment Processor, or other Third Party Providers, and you authorize each Third Party Provider to share your information with us, in connection with your use and access of the Payment Services and/or Third Party Services, including instructing Third Party Providers to execute Transactions in accordance with your instructions and/or to issue credits or debits to your External Account. These authorizations are in addition to any collection and use of any information described elsewhere in these Terms of Service and in the Privacy Policy.</p>
    <p>Third Party Interactions</p>
    <p>When you access third party websites or use Third Party Services, you accept that there are risks in doing so and that So.Social is not responsible for such risks.  We encourage you to be aware when you leave the Platform or access Third Party Services through the Platform and to read the terms and conditions and privacy policy of each third party website or Third Party Service that you visit or utilize.</p>
    <p>You acknowledge and agree that So.Social has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or Third Party Services or by any third party that you interact with through the Payment Services. When you access the website or platform of any Third Party Provider, you agree that you will abide by all terms of use applicable to such use or access.</p>
    <p>Third Party Fees; Indemnity</p>
    <p>You are responsible for any fees, assessments, or other amounts charged by the Bank, Payment Processor, other Third Party Providers or any other third party in connection with your use of the Payment Services and/or any Third Party Services.</p>
    <p>You are responsible for and will pay, indemnify, and hold So.Social harmless from any sales, use, excise, import or export, withholding, value added, or similar tax, and any penalties or interest associated with Taxes, imposed by any authority with respect to any payment to be made to So.Social under these Purchase Terms.</p>
    <p className={s.h4}>Cryptocurrency Transactions</p>
    <p className={s.h4}>Inherent Risks of Cryptocurrency</p>
    <p>Transactions involving cryptocurrency assets involve substantial risks. In considering whether to hold digital assets, you should be aware that the price or value of a digital asset can change rapidly, decrease, and potentially even fall to zero. You acknowledge that So.Social is not responsible for any loss of your digital asset, resulting from theft, loss, or mishandling of private keys outside our control.</p>
    <p>We are not responsible for any digital asset market and we make no representations or warranties concerning the real or perceived value of digital assets.  By using our Platform, you understand and agree that we are not in any way responsible or liable for any losses you may incur by using or transferring digital assets in connection with our Services.</p>
    <p>We do not provide investment advice and any content contained on our Services should not be considered as a substitute for tailored investment advice. The contents of our Site and the Services should not be used as a basis for making investment decisions.</p>

    <Title className={s.h3} text="10. Disclaimers" />
    <p>TRANSACTIONS, INCLUDING BUT NOT LIMITED TO SALES, LISTINGS, CROWDFUNDS, SPLITS, AND OTHER OPERATIONS UTILIZE EXPERIMENTAL SMART CONTRACT AND BLOCKCHAIN TECHNOLOGY, INCLUDING NON-FUNGIBLE TOKENS, CRYPTOCURRENCIES, CONSENSUS ALGORITHMS, AND DECENTRALIZED OR PEER-TO-PEER NETWORKS AND SYSTEMS. USERS ACKNOWLEDGE AND AGREE THAT SUCH TECHNOLOGIES ARE EXPERIMENTAL, SPECULATIVE, AND INHERENTLY RISKY AND MAY BE SUBJECT TO BUGS, MALFUNCTIONS, TIMING ERRORS, HACKING AND THEFT, OR CHANGES TO THE PROTOCOL RULES OF THE ETHEREUM BLOCKCHAIN, THE SOLANA BLOCKCHAIN, OR THE OPTIMISM BLOCKCHAIN (I.E., "FORKS"), WHICH CAN ADVERSELY AFFECT SMART CONTRACTS AND MAY EXPOSE YOU TO A RISK OF TOTAL LOSS, FORFEITURE OF YOUR DIGITAL CURRENCY OR DIGITAL ASSET, OR LOST OPPORTUNITIES TO BUY OR SELL A DIGITAL ASSET.</p>
    <p>SO.SOCIAL MAKES NO WARRANTY THAT (I) THE PLATFORM WILL MEET YOUR REQUIREMENTS, (II) THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, NFTS, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE PLATFORM WILL MEET YOUR EXPECTATIONS.</p>
    <p>WE RESERVE THE RIGHT (BUT NOT THE OBLIGATION), AT OUR SOLE DISCRETION, TO REFUND THE PURCHASED AMOUNT IF THERE IS A TECHNICAL ERROR WITH THE PURCHASED SERVICE.</p>
    <p>SO.SOCIAL RESERVES THE RIGHT TO INVESTIGATE AND, IF APPROPRIATE, SUSPEND OR TERMINATE YOUR ACCOUNT WITHOUT A REFUND IF SO.SOCIAL BELIEVES THAT YOU HAVE VIOLATED THESE TERMS, MISUSED OUR SERVICES, OR BEHAVED IN A WAY THAT WE REGARD AS INAPPROPRIATE OR UNLAWFUL, ON OR OFF OUR SERVICES. WE RESERVE THE RIGHT TO MAKE USE OF ANY PERSONAL, TECHNOLOGICAL, LEGAL, OR OTHER MEANS AVAILABLE TO ENFORCE THE TERMS, AT ANY TIME WITHOUT LIABILITY AND WITHOUT THE OBLIGATION TO GIVE YOU PRIOR NOTICE, INCLUDING, BUT NOT LIMITED TO, PREVENTING YOU FROM ACCESSING THE SERVICES.</p>
    <p>IF YOUR ACCOUNT IS TERMINATED BY YOU OR BY SO.SOCIAL FOR ANY REASON, THESE TERMS CONTINUE AND REMAIN ENFORCEABLE BETWEEN YOU AND SO.SOCIAL, AND YOU WILL NOT BE ENTITLED TO ANY REFUND FOR PURCHASES MADE. </p>
    <p>THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SO.SOCIAL, ITS LICENSORS, AND ITS THIRD PARTY SERVICE PROVIDERS DO NOT WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. SO.SOCIAL DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE SO.SOCIAL ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR SERVICES SAFE, WE DO NOT REPRESENT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>

    <Title className={s.h3} text="11. Limitation of Liability" />
    <p>IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, WARRANTY, OR OTHERWISE, WILL ANY SO.SOCIAL ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE SO.SOCIAL ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID SO.SOCIAL IN THE PREVIOUS SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY, INCLUDING THOSE BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND EVEN IF THE SO.SOCIAL ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. THE FOREGOING LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</p>

    <Title className={s.h3} text="12. Governing Law" />
    <p>These Terms of Service shall be governed by and construed in accordance with the laws of the Republic of Singapore without regard to its conflict of law rules. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act, to the extent applicable, are expressly disclaimed. Unless otherwise required by applicable laws, any dispute, controversy, claim. or difference of any kind whatsoever shall arising out of or relating to these Terms of Service against or relating to So.Social or any Indemnified Party under these Terms of Service shall be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for the time being in force, which rules are deemed to be incorporated by reference in this Section. There will be one (1) arbitrator and the language of the arbitration shall be English. </p>

    <Title className={s.h3} text="13. Miscellaneous" />
    <p>These Terms constitute the entire agreement between you and us regarding your access to and use of the Services. Our failure to exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or provision. If any provision of these Terms is, for any reason, held to be illegal, invalid or unenforceable, the rest of the Terms will remain in effect. You may not assign or transfer any of your rights or obligations under these Terms without our consent. We may freely assign these Terms.</p>

    <Contact />
  </div>
}

export default TermsDoc;
