let env = 'prod';
if (window.location.hostname.includes('so.social')) env = 'prod';
if (window.location.hostname.includes('hypserspaces')) env = 'dev';
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) env = 'local';

export const deviceWidth = 1000;

const isHarmonyOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor;
  return (
    userAgent.toLowerCase().includes("harmony") ||
    userAgent.toLowerCase().includes("honor")
  );
};

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const isNative = () => {
  return window.innerWidth < deviceWidth || isHarmonyOS() || isMobile();
};

const isIos = () => /iPad|iPhone|iPod|Mac/.test(navigator.userAgent);

const getNativeHeaders = () => {
  var result = sessionStorage.getItem("native");
  if (!result) {
    return {};
  }
  var jsonStr = atob(result)
  if (!jsonStr) {
    return {};
  }
  var headers = JSON.parse(jsonStr);
  if (!headers) {
    headers = {};
  }
  return headers;
};

export {
  isHarmonyOS,
  isMobile,
  isNative,
  isIos,
  getNativeHeaders,
  env
};
