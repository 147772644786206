import news1 from './images/news1.jpg';
import news2 from './images/news2.jpg';
import news3 from './images/news3.jpg';

const newList = [
  {
    img: news1,
    title: 'So.Social: Creating a More Sovereign DAO',
    time: '26 April 2024',
    url: 'https://u.today/press-releases/sosocial-creating-a-more-sovereign-dao'
  },
  {
    img: news2,
    title: 'So.Social redefines the Web3 social landscape',
    time: '25 April 2024',
    url: 'https://cointelegraph.com/press-releases/sosocial-redefines-the-web3-social-landscape'
  },
  {
    img: news3,
    title: "Beyond Telegram: So.Social's Journey Into Web3 Technologies and Social Networking",
    time: '25 April 2024',
    url: 'https://news.bitcoin.com/beyond-telegram-so-socials-journey-into-web3-technologies-and-social-networking/'
  },
]

export default newList