import community from "./images/community.png";
import linkingSocial from "./images/linking-social.png";
import communityTrust from "./images/community-trust.png";

const acList = [
  {
    img: community,
    title: 'Social Organization',
    text: 'Sovereignty',
  },
  {
    img: linkingSocial,
    title: 'Linking Social',
    text: 'Interactions with Assets',
  },
  {
    img: communityTrust,
    title: 'Community Trust',
    text: 'and Transparency',
  },
]

export default acList