import React from "react";
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import news from 'assets/news.svg';
import { isNative } from "@/utils/env";
import twitter from 'assets/twitter.svg';
import logo from 'assets/logo.webp';
import Cta from "../animation/components/cta";
import Menu from "../menu";
import styPc from './index.module.less';
import styMobile from './mobile.module.less';

const s = isNative() ? styMobile : styPc;

const Header = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const backHome = () => navigate(isNative() ? '/mobile' : '/')

  const success = () => {
    messageApi.open({
      className: s.success,
      duration: 3,
      icon: null,
      content: 'Coming soon...',
    });
  };

  return <div className={s.header}>
    {contextHolder}
    <div className={s.logo} onClick={backHome}>
      <img width={'100%'} height={'100%'} alt="logo" src={logo} />
    </div>
    <div className={s.content}></div>
    <div className={s.icon}>
      {
        isNative() ? <>
          <div className={s.svg}>
            <object type="image/svg+xml" data={news} />
            <div className={s.obj}  onClick={() => {
              window.open('https://sosocial.medium.com/')
            }} />
          </div>
          <div className={s.svg}>
            <object type="image/svg+xml" data={twitter} />
            <div className={s.obj}  onClick={() => {
              window.open('https://twitter.com/SoSocialGlobal')
            }} />
          </div>
          <Menu />
        </> :
        <>
          <img alt="新闻" onClick={() => window.open('https://sosocial.medium.com/')} src={news} />
          <img alt="twitter" onClick={() => window.open('https://twitter.com/SoSocialGlobal')} src={twitter} />
          <div className={s.connect} onClick={success}>
            <Cta keyword="CONNECT" className={s.con} />
          </div>
          <Menu />
        </>
      }
    </div>
  </div>
}

export default Header;
