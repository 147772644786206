import React, { useEffect, useRef, useState } from "react";
import newList from "./newsList";
import Title from "../title";
import Empty from "../empty";
import { isNative } from "@/utils/env";
import stylePc from './index.module.less';
import styleMobile from './mobile.module.less';

const s = isNative() ? styleMobile : stylePc;

const News = () => {
  const page5IframeRef = useRef(null);
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const loadingEntry = entries[0];
        if (loadingEntry.isIntersecting) {
          setShow(true);
        }
      },
      {
        threshold: 0.6, // 提前加载高度
      },
    )
    observer.observe(ref.current);
  }, []);

  useEffect(() => {
    if (page5IframeRef.current) {
      setTimeout(() => {
          const frameWindow = page5IframeRef.current?.contentWindow
          frameWindow.location.reload()
      }, 2000);
    }
  }, []);

  return <div className={s.news} ref={ref}>
    {
      !isNative() && show ? <iframe
        id="page5Iframe"
        title="page5Iframe"
        className={s.iframe}
        ref={page5IframeRef}
        src="./static/page5-background.html"
        scrolling="no" frameBorder="0"
        allowFullScreen
      />: null
    }
    <div className={isNative() ? 'radius-mobile' : 'radius'}> 
      <Empty height="0.6rem" />
      <Title className={s.ttitle} text="Latest News" />
      <Empty height="0.6rem" />
      <div className={s.newsList}>
        {
          newList.map((item => <div className={s.each} key={item.title}>
            <div className={s.wrapper}>
              <div
                className={s.img}
                style={{ backgroundImage: `url(${item.img})`}}
                onClick={() => window.open(item.url)}
              />
              <div className={s.title} onClick={() => window.open(item.url)}>
                {item.title}
              </div>
              <div className={s.time}>
                {item.time}
              </div>
            </div>
          </div>))
        }
      </div>
      <Empty height="0.6rem" />
    </div>
  </div>
}

export default News;
